import React, { useContext, useEffect, useRef, useState } from "react";
import AddsList from "./AdsList";
import { AppContext } from "../App";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style/faq.css";
import PagePagintaion from "./PagePagination";
import { scrollToTop } from "./Methods";
import {ReactComponent as ArrowIcon} from '../icon/arrowDown.svg';
export default function Faq(){
    const params = useParams();
    const {cname} = params;
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentPage = useRef(1);

    const [mainData,setMainData] = useState({
        ads: null,
        data : null,
        persianName : null,
        totalNumber : 0,
        loading:false,
    });
    
    useEffect(() => {
        document.title = "سوالات حقوقی مدیریت روستایی";
        setCity(cname ? cname : "melli");
        fetchData();
    }, []);
    function fetchData(){
        scrollToTop();
        currentPage.current = queryParams.get("p") ? queryParams.get("p") : 1;
        const cityName = cname ? cname : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("pageCount",10);
        data.append("pageIndex",currentPage.current);
        fetch("https://web.beladkhabar.ir/Enrollment/getFaq",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(json.msg);
            }
            const tempData = json[0];
            setMainData({
                ads:tempData.ads,
                data:tempData.dt[0].faq,
                persianName:tempData.persianName,
                totalNumber:tempData.dt[0].recordCounts,
                loading:false
            });
        })
        .catch((err) => {
            setMainData({...mainData,loading : false})
        });
    }
    function changePage(newPage){
        setMainData({...mainData,loading : true});
        queryParams.set("p",newPage);
        window.history.pushState({},"",`${window.location.pathname}?${queryParams}`);
        fetchData();
    }
    return <>
        <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>)
                }
                {
                    <p className="faFont textSize9 textClr3">
                        سوالات حقوقی مدیریت روستایی
                    </p>
                }
            </div>
            <div className="grid tableListGrid">
                <div className="flex flexCol">
                    {
                        mainData.data ? <div className="flex flexCol faqMainDiv">
                            <h1 className="faFont textSize1 textClr3 faqTitle">
                                سوالات حقوقی مدیریت روستایی
                            </h1>
                            <div className="flex flexCol faqList">
                            {
                                mainData.data.map((item,index)=>{
                                    return <div className="flex flexCol faqListItem" key={index}>
                                        <button className="faFont textSize1 textClr3 faqQuestion rel" onClick={(e)=>e.target.classList.toggle("clicked")}>
                                            {
                                                item.question
                                            }
                                            <ArrowIcon />
                                        </button>
                                        <div className="flex flexCol faqAnswerDiv">
                                            <p className="faFont textSize9 textClr5 faqAnswer flex alignCenter gap5">
                                                <span>پاسخ :</span>
                                            {
                                                item.answer
                                            }
                                            </p>
                                        </div>
                                    </div>
                                })
                            }
                            </div>
                            <PagePagintaion data={{totalNumber:mainData.totalPage,
                                changePage : changePage,
                                currentPage:currentPage}} />
                        </div> : ""
                    }
                </div>
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
    </>
}