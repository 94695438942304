import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import {ReactComponent as TimeLogo} from "../icon/date.svg";
import {ReactComponent as VisitLogo} from "../icon/Visit.svg";
import {ReactComponent as FaceBookLogo} from "../icon/whiteFacebook.svg";
import {ReactComponent as LinkedinLogo} from "../icon/whiteLinkedin.svg";
import {ReactComponent as TelegramLogo} from "../icon/whiteTelegram.svg";
import {ReactComponent as TwitterLogo} from "../icon/whiteTwitter.svg";
import {ReactComponent as WhatsappLogo} from "../icon/whiteWhatsapp.svg";

import "../style/postDetails.css";
import { MyComponent } from "./Methods";
import AddsList from "./AdsList";
import NewsSwipeList from "./NewsSwipeList";
export default function PostDetails(){
    const navigate = useNavigate();
    const params = useParams();
    const {currentCity} = useContext(AppContext);
    const [,setCity] = currentCity;
    const cityName = params.name ? params.name : "melli";
    const postId = params.id;

    const [mainData,setMainData] = useState({
        ads:null,
        newsData : null,
        relative : null,
    });
    useEffect(() => {
        fetchData();
    }, [postId,cityName]);
    useEffect(() => {
        setCity(params.name ? params.name : "melli");
        fetchData();
    }, []);
    function fetchData(){
        if(!postId){
            navigate("/");
        }
        const data = new FormData();
        data.append("name", cityName);
        data.append("pid", postId);
        data.append("password", "1234567890");
        fetch("https://web.beladkhabar.ir/Enrollment/GetPageByID",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            const temp = json[0];
            if(temp.page && temp.page[0]){
                document.title = temp.page[0].title;
            }
            setMainData({
                newsData : temp.page[0],
                ads:temp.ads,
                relative : temp.relatedNews
            });
        })
        .catch(() => {
            navigate("/");
        });
    }
    return <>
        <section className="grid mainDivGrid container">
        {
            mainData.newsData ? 
            <div className="flex flexCol newsMainDiv">
                <h1 className="faFont textSize14 textClr3 pageTitle">
                    {
                        mainData.newsData.title
                    }
                </h1>
                <div className="flex flexCol newsImageDiv">
                    <img src={mainData.newsData.src} alt={mainData.newsData.title} className="newsImage" />
                </div>
                <div className="flex alignCenter postInfoDiv">
                    <p className="faFont textSize9 textClr5 flex alignCenter gap10 dateText">
                        <TimeLogo />
                        {
                            mainData.newsData.createDate
                        }
                    </p>
                    <p className="faFont textSize9 textClr5 flex alignCenter gap10 dateText">
                        <VisitLogo />
                        {
                            mainData.newsData.visiteCount
                        }
                    </p>
                </div>
                <div className="divider"></div>
                {
                    mainData.newsData.pageabstract && <h2 className="faFont textClr3 textSize1 newsShortText justifyText">
                        {
                            mainData.newsData.pageabstract
                        }
                    </h2>
                }
                {
                    mainData.newsData.conents && <div className="flex flexCol faFont newsContentDiv gap5">
                        {
                            MyComponent({htmlContent:mainData.newsData.conents})
                        }
                    </div>
                }
                <div className="divider"></div>
                <div className="flex alignCenter justifyBetween shareDiv">
                    <p className="faFont textSize9 textClr3">
                        اشتراک گذاری مطلب
                    </p>
                    <div className="flex alignCenter gap5">
                        <a href={"whatsapp://send?text="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                            <WhatsappLogo />
                            <span className="hideText">share whatsapp</span>
                        </a>
                        <a href={"https://telegram.me/share/url?url="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem ">
                            <TelegramLogo />
                            <span className="hideText">share telegram</span>
                        </a>
                        <a href={"https://twitter.com/share?url="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                            <TwitterLogo />
                            <span className="hideText">share twitter</span>
                        </a>
                        <a href={"https://www.linkedin.com/shareArticle?mini=true&url=="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                            <LinkedinLogo />
                            <span className="hideText">share linkedin</span>
                        </a>
                        <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                            <FaceBookLogo />
                            <span className="hideText">share facebook</span>
                        </a>
                    </div>
                </div>
            </div>
            : 
            <div className="flex flexCol newsLoadingDiv rel"></div>
        }
            <div className="flex flexCol row1LeftDiv">
                <AddsList data={{"ads":mainData.ads}}/>
            </div>
        </section>
        {
            mainData.relative && <NewsSwipeList data={{news:mainData.relative}} info={{title:"اخبار مرتبط",hasLocation:false,path:"/"}}/>
        }
    </>
}