import React from "react";
import { Link } from "react-router-dom";
import {ReactComponent as DateLogo} from '../icon/date.svg';
import {ReactComponent as ArrowLeft} from '../icon/arrowLeft.svg';
export default function NewsItem(prop) {
    const info = prop.info;
    return <div className="grid newsListItem rel">
        <Link to={info.link} target="_blank">
            <img src={info.src} alt={info.title} className="newsListItemImage" />
        </Link>
        <div className="flex flexCol newsListItemInfoDiv">
            <Link className="faFont textSize1 newListItemTitle" to={info.link} target="_blank">{info.title}</Link>
            <p className="faFont textSize9 textClr7 justifyText">{info.pageAbstract}</p>
            <div className="flex alginCenter mar5 justifyBetween newsListItemFooterDiv">
                <p className="faFont textSize9 textClr5 dateText flex alignCenter gap5">
                    {info.creationDate}
                    <DateLogo />
                </p>
            </div>
        </div>
    </div>
}