import React, { useRef } from "react";
import '../style/latestNews.css';
import 'swiper/css';
import '../style/latestNews.css';
export default function LatestNews(props){
    const data = props.data;
    const winX = useRef(0);
    const time = useRef(850);
    if(data.news){
        if(data.news.length > 0){
            let win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName('body')[0],
            x = win.innerWidth || docElem.clientWidth || body.clientWidth;
            let mul = 15;
            if(x > 1350){
                x = 1350;
            }
            if(x>768){
                mul = 100;
            }
            const testText = document.querySelector(".testText");
            if(testText){
                data && data.news && data.news.map((item)=>{
                    const span = document.createElement("span");
                    span.innerText = item.text;
                    testText.append(span)
                })
                winX.current = Math.floor(x);
                time.current = Math.floor(testText.clientWidth/x * mul);
            }
        }
    }
    return <>
    {
         !data.news ? <div className="latestTextNewsLoadingDiv rel">
            <p className="testText faFont textSize9"></p>
        </div> : 
        data && data.news && data.news.length > 0 ? <section className="flex flexCol latestSection">
            <div className="latestTitleContainer ">
                <div className="flex alignCenter container latestTitleDiv rel">
                    <p className="faFont textSize12 textClrF mar5">
                        اخبار فوری
                    </p>
                </div>
            </div>
            <div className="flex latestNewsList alignCenter hideScrollBar container">
                <p className="faFont textSize9 textClrF latestNewsText flex alignCenter" style={{animation:"movingList "+time.current+"s linear infinite" ,paddingRight:winX.current}} >
                    {
                        data.news.map((item,index)=>{
                            return <span className="rel latestNewsSpan" key={index}>{item.text}</span>
                        })
                    }
                </p>
            </div>
        </section> : ""
    }   
    </>
}