import React from "react";
import "../style/radio.css";
import {ReactComponent as Mic} from '../icon/mic.svg';
import { ReactComponent as LeftArrow } from "../icon/arrowLeft.svg";
import { Link, useParams } from "react-router-dom";
export default function Radio(props){
    const param = useParams();
    const city = param.name ? param.name : "";
    const data = props.data;
    
    return <>
    {
        data && data.list && data.list.length > 0 ? <div className="flex flexCol radioMainDiv">
        <div className="flex alignCenter partTitleDiv justifyBetween" style={{borderBottom: "2px solid #1e88e5"}}>
            <p className="faFont textSize1 textClrF partTitle" style={{backgroundColor:"#1e88e5"}}>رادیو بلاد</p>
            <Link to={city !== "" ? "/"+city+"/podcast" : "/podcast"} className="faFont textSize9 textClr3 flex alignCenter gap5 seeAll">مشاهده آرشیو <LeftArrow /></Link>
        </div>  
        <div className="flex flexCol podCastList gap20">
            {
                data.list.map((item,index)=>{
                return <div className="flex flexCol padcostItem gap5" key={index}>
                            <div className="flex flexCol radioImageDiv rel">
                                <img src={item.cover} alt={item.title} className="radioImage" />
                                <div className="radioSvg flex flexCenter">
                                    <Mic/>
                                </div>
                            </div>
                        <audio src={item.url} controls />
                    </div>
                })
            }
        </div>
    </div> : ""
    }
    </>
    
}