import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../style/video.css";
import AddsList from "./AdsList";
import {ReactComponent as FaceBookLogo} from "../icon/whiteFacebook.svg";
import {ReactComponent as LinkedinLogo} from "../icon/whiteLinkedin.svg";
import {ReactComponent as TelegramLogo} from "../icon/whiteTelegram.svg";
import {ReactComponent as TwitterLogo} from "../icon/whiteTwitter.svg";
import {ReactComponent as WhatsappLogo} from "../icon/whiteWhatsapp.svg";
export default function Video(){
    const navigate = useNavigate();
    const params = useParams();
    const {id,cname} = params;
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const [mainData,setMainData] = useState({
        ads: null,
        videoData : null,
        persianName : null,
    });
    useEffect(() => {
        setCity(cname ? cname : "melli");
        fetchData();
    }, []);
    function fetchData(){
        if(!id){
            navigate("/");
            return;
        }
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("pid",id);
        fetch("https://web.beladkhabar.ir/Enrollment/getVideoById",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(json.msg);
            }
            const tempData = json[0];
            setMainData({
                ads : tempData.ads,
                videoData : tempData.video[0],
                persianName : tempData.persianName
            });
            document.title = "ویدیو " + tempData.video[0].title;
        })
        .catch((err) => {
        });
    }
    return <>
        <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/"+params.name}>{mainData.persianName}</Link>)
                }
                {
                    mainData.persianName && <Link className="faFont textSize9 textClr3 directionLink rel" to={mainData.persianName !== "ملی" ? "/" + params.name +"/videos" : "/videos" }>
                        ویدیوها
                    </Link>
                }
                {
                    mainData.videoData && <p className="faFont textSize9 textClr3">
                    {
                        "ویدیو " + mainData.videoData.title
                    }
                    </p>
                }
            </div>
            <div className="grid newListGrid">
                {
                    mainData.videoData ? <div className="flex flexCol gap20">
                        <div className="flex flexCol videoDiv">
                            <video className="mainVideo" poster={mainData.videoData.cover} controls>
                                <source src={mainData.videoData.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <p className="faFont textSize11 textClr3 videoDesc">
                                {
                                    mainData.videoData.title
                                }
                            </p>
                            <div className="divider"></div>
                            <div className="flex alignCenter justifyBetween shareDiv">
                                <p className="faFont textSize9 textClr3">
                                    اشتراک گذاری مطلب
                                </p>
                                <div className="flex alignCenter gap5">
                                    <a href={"whatsapp://send?text="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <WhatsappLogo />
                                        <span className="hideText">share whatsapp</span>
                                    </a>
                                    <a href={"https://telegram.me/share/url?url="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem ">
                                        <TelegramLogo />
                                        <span className="hideText">share telegram</span>
                                    </a>
                                    <a href={"https://twitter.com/share?url="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <TwitterLogo />
                                        <span className="hideText">share twitter</span>
                                    </a>
                                    <a href={"https://www.linkedin.com/shareArticle?mini=true&url=="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <LinkedinLogo />
                                        <span className="hideText">share linkedin</span>
                                    </a>
                                    <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <FaceBookLogo />
                                        <span className="hideText">share facebook</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <a href="https://eitaa.com/beladkhabar" className="flex flexCol" target="_blank" rel="noreferrer" draggable="false">
                            <img src="/image/socialImage.jpg" alt="socialLink" className="middleImage" />
                        </a>
                    </div> : <div className="flex flexCol videoLoading rel"></div>
                }
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
    </>
}