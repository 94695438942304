import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { Link, useLocation, useParams } from "react-router-dom";
import { scrollToTop } from "./Methods";
import AddsList from "./AdsList";
import {ReactComponent as PlayLogo} from '../icon/play.svg';
import "../style/player.css";
import PagePagintaion from "./PagePagination";
export default function PodcastList(){
    const params = useParams();
    const {cname} = params;
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentPage = useRef(1);
    const customMusicPlayer = useRef(null);
    const timePassed = useRef(null);
    const totalTime = useRef(null);
    const hasTotalTime = useRef(false);
    const progressDiv = useRef(false);
    const progressBar = useRef(false);
    const volumeDiv = useRef(false);
    const playerMainDiv = useRef(false);
    const volumeProgress = useRef(false);
    const [repeatMode,setRepeatMode] = useState(false);
    const [currentPodcastIndex,setCurrentPodcastIndex] = useState(null);
    const [isPlaying,setIsPlaying] = useState(false);
    const [mainData,setMainData] = useState({
        data: null,
        ads: null,
        totalNumber : 0,
        loading : false,
        persianName : null,
    });
    const [activeItem,setActiveItem] = useState(null);
    useEffect(() => {
        document.title = "رادیو بلاد";
        setCity(cname ? cname : "melli");
        setTimeout(() => {
        fetchData();
        }, 5000);
    }, []);
    function fetchData(){
        scrollToTop();
        currentPage.current = queryParams.get("p") ? queryParams.get("p") : 1;
        const cityName = cname ? cname : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("pageCount",8);
        data.append("pageIndex",currentPage.current);
        fetch("https://web.beladkhabar.ir/Enrollment/getpodcasts",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(json.msg);
            }
            const tempData = json[0];
            setMainData({
                data:tempData.dt[0].dt,
                ads:tempData.ads,
                persianName: tempData.persianName,
                loading:false,
                totalNumber : Math.ceil(+tempData.dt[0].cnt / 10) ,
            })
            generatePlayer();
        })
        .catch((err) => {
            setMainData({...mainData,loading : false})
        });
    }
    function changePage(newPage){
        setMainData({...mainData,loading : true});
        queryParams.set("p",newPage);
        window.history.pushState({},"",`${window.location.pathname}?${queryParams}`);
        fetchData();
    }
    function playItemClickHandler(itemData,index){
        if(activeItem && activeItem.source === itemData.url){
            return;
        }
        setActiveItem({source:itemData.url ,cover:itemData.cover,title:itemData.title});
        hasTotalTime.current = false;
        setCurrentPodcastIndex(index);
        progressBar.current.style.width = `0px`;
        playAudio();
    }
    function generatePlayer(){
        customMusicPlayer.current = document.getElementById("customMusicPlayer");
        totalTime.current = document.querySelector(".totalTime");
        timePassed.current = document.querySelector(".timePassed");
        progressDiv.current = document.querySelector(".progressDiv");
        progressBar.current = document.querySelector(".progressBar");
        volumeDiv.current = document.querySelector(".volumeDiv");
        playerMainDiv.current = document.querySelector(".playerMainDiv");
        volumeProgress.current = document.querySelector(".volumeProgress");
        customMusicPlayer.current.addEventListener("timeupdate", updateTimeElapsed);
        progressDiv.current.addEventListener("click", (e) => changeTimeHandler(e));
        volumeDiv.current.addEventListener("click", (e) => changeVolumeHandler(e));
        customMusicPlayer.current.addEventListener("ended",musicEndHandler);
    }
    function playAudio(){
        if(!customMusicPlayer.current){
            return;
        }
        totalTime.current.innerText = "00 : 00";
        timePassed.current.innerText = "00 : 00";
        setIsPlaying(true);
    }
    function updateTimeElapsed(){
        if(customMusicPlayer.current.duration && !hasTotalTime.current){
            const time = formatTime(Math.round(customMusicPlayer.current.duration));
            totalTime.current.innerText = `${time.minutes}:${time.seconds}`;
            totalTime.current.setAttribute("datetime", `${time.minutes}m ${time.seconds}s`);
            hasTotalTime.current = true;
        }
        const time = formatTime(Math.round(customMusicPlayer.current.currentTime));

        timePassed.current.innerText = `${time.minutes}:${time.seconds}`;
        timePassed.current.setAttribute("datetime", `${time.minutes}m ${time.seconds}s`);

        let psTime = customMusicPlayer.current.currentTime;
        let duration = customMusicPlayer.current.duration;

        let passedPercent = (psTime * 100) / duration;
        let widthInPx = (progressDiv.current.offsetWidth * passedPercent) / 100;
        progressBar.current.style.width = `${widthInPx}px`;
    }
    function togglePlay(){
        if(isPlaying){
            customMusicPlayer.current.pause();
        }else{
            customMusicPlayer.current.play();
        }
        setIsPlaying(!isPlaying);
    }
    function closePlayer(){
        customMusicPlayer.current.pause();
        setActiveItem(null);
        setIsPlaying(false);
    }
    function formatTime(timeInSeconds) {
        const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
        return {
          minutes: result.substr(3, 2),
          seconds: result.substr(6, 2),
        };
    }
    function changeTimeHandler(e) {
        if (!customMusicPlayer.current.duration) {
          return;
        }
        let posX;
        if (e.type == "touchend") {
          var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
          posX = touch.pageX;
        } else if (e.type === "click") {
          posX = e.clientX;
        }
        let volumePercent = (posX * 100) / progressDiv.current.offsetWidth;
        let newTime = (volumePercent * customMusicPlayer.current.duration) / 100;
        customMusicPlayer.current.currentTime = Math.round(newTime);
    }
    function changeVolumeHandler(e) {
        let posX;
        if (e.type == "touchend") {
          var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
          posX = touch.pageX;
        } else if (e.type === "click") {
          posX = e.clientX;
        }
        posX = posX - volumeDiv.current.offsetLeft - playerMainDiv.current.offsetLeft;
        let volumePercent = (posX * 100) / volumeDiv.current.offsetWidth;
        customMusicPlayer.current.volume = volumePercent / 100;
        let widthInPx = (volumeDiv.current.offsetWidth * volumePercent) / 100;
        volumeProgress.current.style.width = `${widthInPx}px`;
    }
    function changeTime(newValue) {
        if (!customMusicPlayer.current.duration) {
            return;
        }
        const currentTime = customMusicPlayer.current.currentTime;
        if (newValue > 0 && newValue + currentTime < customMusicPlayer.current.duration) {
            customMusicPlayer.current.currentTime = currentTime + newValue;
        } else if (newValue < 0 && currentTime - newValue > 5) {
            customMusicPlayer.current.currentTime = currentTime + newValue;
        }
    }
    function changeMusic(step) {
        progressBar.current.style.width = `0px`;
        customMusicPlayer.current.pause();
        totalTime.current.innerText = "00:00";
        timePassed.current.innerText = "00:00";

        const allData = document.querySelectorAll(".playLogoSpan");
        let tempIndex = currentPodcastIndex + step;
        if (tempIndex < 0) {
          tempIndex = allData.length - 1;
        } else if (tempIndex >= allData.length) {
          tempIndex = 0;
        }
        setActiveItem({source:allData[tempIndex].dataset.url ,cover : allData[tempIndex].dataset.cover , title: allData[tempIndex].dataset.title});
        hasTotalTime.current = false;
        setCurrentPodcastIndex(tempIndex);
        progressBar.current.style.width = `0px`;
        playAudio();
    }
    function musicEndHandler() {  
        customMusicPlayer.current.pause();
        const allData = document.querySelectorAll(".playLogoSpan");
        const repeateMusicBtn = document.querySelector(".repeateMusicBtn");
        if (repeateMusicBtn.classList.contains("repeatOn")) {
          customMusicPlayer.current.currentTime = 0;
          customMusicPlayer.current.play();
        } else if (allData.length > 1) {
          const tempIndex = currentPodcastIndex + 1;
          if (tempIndex < allData.length) {
              setCurrentPodcastIndex(tempIndex);
              setActiveItem({source:allData[tempIndex].dataset.url ,cover : allData[tempIndex].dataset.cover , title: allData[tempIndex].dataset.title});
              hasTotalTime.current = false;
        } else {
              setCurrentPodcastIndex(0);
              setActiveItem({source:allData[0].url ,data:allData[0]});
              hasTotalTime.current = false;
          }
        } else {
          customMusicPlayer.current.currentTime = 0;
          setIsPlaying(false);
          hasTotalTime.current = false;
          
        }
    }
    return <>
    <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>)
                }
                <h1 className="faFont textSize9 textClr3">
                    رادیو بلاد
                </h1>
            </div>
            <div className="grid tableListGrid">
                <div className="flex flexCol podcastMainDiv">
                    {
                        mainData.data ? <div className="flex flexCol podcastList">
                            {
                                mainData.data.map((item,index)=>{
                                    return <div className="grid podcastItem" key={index}>
                                        <img src={item.cover} alt={item.title} className="podcastItemImage" />
                                        <p className="faFont textSize9 textClr3 podcastName">
                                        {
                                            item.title
                                        }
                                        </p>
                                        <a href={item.url} className="downloadItem faFont textSize8 textClr3 flex alignCenter">
                                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg>
                                            <span className="downloadTextSpan">
                                                دانلود
                                            </span>
                                        </a>
                                        <button className="flex alignCenter gap5 playPodcastBtn faFont textClrF textSize8" onClick={()=>playItemClickHandler(item,index)}>
                                            <PlayLogo />
                                            <span className="playLogoSpan" data-url={item.url} data-cover={item.cover} data-title={item.title} >پخش</span>
                                        </button>
                                    </div>
                                })
                            }
                        </div> : <div className="flex flexCol loadingPodcastList rel"></div>
                    }
                     <PagePagintaion data={{totalNumber:mainData.totalNumber,
                                changePage : changePage,
                                currentPage:currentPage}} />
                </div>
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
        <section className={activeItem ? "flex flexCol playerSection rtl show" : "flex flexCol playerSection rtl"}>
            <div className="progressDiv">
                <div className="progressBar"></div>
            </div>
            <div className="playerMainDiv grid rel">
                <div className="grid alignCenter musicInfoDiv">
                    <img src={activeItem && activeItem.cover} alt="" className="musicInfoImg"/>
                    <div className="flex flexCol">
                        <p className="faFont textSize3 musicInfoName">{activeItem && activeItem.title}</p>
                        <p className="siteName faFont textSize3">بلاد خبر</p>
                    </div>
                </div>
                <div className="musicPlayerActionDiv alignCenter flex gap5">
                    <div className="timeDiv flex alignCenter gap5">
                        <p className="faFont textSize4 timePassed"></p>
                        <p className="faFont textSize4 slash">/</p>
                        <p className="faFont textSize4 totalTime"></p>
                    </div>
                    <button className={repeatMode ? "btn flex flexCenter repeateMusicBtn repeatOn" : "btn flex flexCenter repeateMusicBtn"} onClick={()=>setRepeatMode(!repeatMode)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 6h-5v2h4v9H4V8h5v3l5-4-5-4v3H3a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"></path></svg>
                    </button>
                    <div className="flex gap5 volumeSection alignCenter">
                        <div className="flex volumeDiv flexCol">
                            <div className="volumeProgress"></div>
                        </div>
                        <button className="btn flex flexCenter speakerIcon">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.75a.75.75 0 00-1.264-.546L4.703 7H3.167a.75.75 0 00-.7.48A6.985 6.985 0 002 10c0 .887.165 1.737.468 2.52.111.29.39.48.7.48h1.535l4.033 3.796A.75.75 0 0010 16.25V3.75zM15.95 5.05a.75.75 0 00-1.06 1.061 5.5 5.5 0 010 7.778.75.75 0 001.06 1.06 7 7 0 000-9.899z"></path><path d="M13.829 7.172a.75.75 0 00-1.061 1.06 2.5 2.5 0 010 3.536.75.75 0 001.06 1.06 4 4 0 000-5.656z"></path></svg>
                        </button>
                    </div>
                    <button className="btn flex flexCenter nextMusicBtn" onClick={()=>changeMusic(1)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M500.5 231.4l-192-160C287.9 54.3 256 68.6 256 96v320c0 27.4 31.9 41.8 52.5 24.6l192-160c15.3-12.8 15.3-36.4 0-49.2zm-256 0l-192-160C31.9 54.3 0 68.6 0 96v320c0 27.4 31.9 41.8 52.5 24.6l192-160c15.3-12.8 15.3-36.4 0-49.2z"></path></svg>
                    </button>
                    <button className="btn flex flexCenter prevMusicBtn" onClick={()=>changeMusic(-1)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M459.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4L288 214.3V256v41.7L459.5 440.6zM256 352V256 128 96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160C4.2 237.5 0 246.5 0 256s4.2 18.5 11.5 24.6l192 160c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V352z"></path></svg>
                    </button>
                    <button className="btn flex flexCenter forwardBtn" onClick={()=>changeTime(5)}>
                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M19.4807 7.08981C19.2207 6.76981 18.7507 6.71977 18.4307 6.97977C18.1107 7.23977 18.0608 7.70981 18.3208 8.02981C19.4508 9.42981 20.0808 11.0898 20.1408 12.8298C20.3008 17.3098 16.7807 21.0898 12.2907 21.2398C7.81073 21.3998 4.03076 17.8798 3.88076 13.3898C3.73076 8.8998 7.24073 5.12977 11.7307 4.97977C12.3007 4.95977 12.9007 5.0098 13.5507 5.1398C13.5907 5.1498 13.6307 5.1398 13.6707 5.1398C13.7707 5.1898 13.8908 5.21976 14.0008 5.21976C14.1708 5.21976 14.3307 5.16977 14.4707 5.04977C14.7907 4.78977 14.8408 4.31978 14.5908 3.99978L12.6107 1.52981C12.3507 1.20981 11.8807 1.14976 11.5607 1.40976C11.2407 1.66976 11.1908 2.13981 11.4408 2.45981L12.2708 3.48978C12.0808 3.47978 11.8808 3.46977 11.6908 3.47977C6.38075 3.65977 2.21077 8.1398 2.40077 13.4498C2.59077 18.7598 7.06075 22.9298 12.3707 22.7398C17.6807 22.5498 21.8507 18.0798 21.6607 12.7698C21.5607 10.7098 20.8207 8.73981 19.4807 7.08981Z" fill="#eee"/>
                            <path d="M12.3806 16.9198H10.0906C9.68065 16.9198 9.34065 16.5798 9.34065 16.1698C9.34065 15.7598 9.68065 15.4198 10.0906 15.4198H12.3806C12.8106 15.4198 13.1606 15.0698 13.1606 14.6398C13.1606 14.2098 12.8106 13.8598 12.3806 13.8598H10.0906C9.85065 13.8598 9.6206 13.7398 9.4806 13.5498C9.3406 13.3598 9.30062 13.0998 9.38062 12.8698L10.1406 10.5798C10.2406 10.2698 10.5306 10.0698 10.8506 10.0698H13.9106C14.3206 10.0698 14.6606 10.4098 14.6606 10.8198C14.6606 11.2298 14.3206 11.5698 13.9106 11.5698H11.3906L11.1306 12.3598H12.3806C13.6406 12.3598 14.6606 13.3798 14.6606 14.6398C14.6606 15.8998 13.6406 16.9198 12.3806 16.9198Z" fill="#fff"/>
                        </svg>
                    </button>
                    <button className="btn flex flexCenter backwardBtn" onClick={()=>changeTime(-5)}>
                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.3806 16.9198H10.0906C9.68061 16.9198 9.34061 16.5798 9.34061 16.1698C9.34061 15.7598 9.68061 15.4198 10.0906 15.4198H12.3806C12.8106 15.4198 13.1606 15.0698 13.1606 14.6398C13.1606 14.2098 12.8106 13.8598 12.3806 13.8598H10.0906C9.85061 13.8598 9.62062 13.7398 9.48062 13.5498C9.34062 13.3598 9.30062 13.0998 9.38062 12.8698L10.1406 10.5798C10.2406 10.2698 10.5306 10.0698 10.8506 10.0698H13.9106C14.3206 10.0698 14.6606 10.4098 14.6606 10.8198C14.6606 11.2298 14.3206 11.5698 13.9106 11.5698H11.3906L11.1306 12.3598H12.3806C13.6406 12.3598 14.6606 13.3798 14.6606 14.6398C14.6606 15.8998 13.6406 16.9198 12.3806 16.9198Z" fill="#eee"/>
                            <path opacity="0.4" d="M11.9998 3.47994C11.9198 3.47994 11.8399 3.48995 11.7599 3.48995L12.5798 2.46993C12.8398 2.14993 12.7898 1.66994 12.4598 1.41994C12.1298 1.16994 11.6699 1.20994 11.4099 1.53994L9.42984 4.00997C9.41984 4.01997 9.41985 4.03995 9.40985 4.04995C9.37985 4.08995 9.35984 4.13996 9.33984 4.18996C9.31984 4.22996 9.29986 4.26997 9.28986 4.31997C9.27986 4.36997 9.27985 4.40998 9.27985 4.45998C9.27985 4.50998 9.27985 4.55994 9.27985 4.60994C9.27985 4.62994 9.27985 4.63993 9.27985 4.65993C9.28985 4.68993 9.30985 4.71996 9.31985 4.74996C9.33985 4.79996 9.35985 4.83997 9.37985 4.88997C9.40985 4.92997 9.43985 4.96996 9.46985 4.99996C9.48985 5.02996 9.50986 5.05997 9.53986 5.07997C9.54986 5.08997 9.56984 5.09994 9.58984 5.10994C9.61984 5.12994 9.64986 5.13993 9.66986 5.15993C9.71986 5.18993 9.77984 5.20993 9.83984 5.21993C9.86984 5.22993 9.89984 5.22995 9.92984 5.23995C9.95984 5.23995 9.97986 5.24996 10.0099 5.24996C10.0399 5.24996 10.0598 5.23994 10.0798 5.22994C10.1098 5.22994 10.1399 5.23994 10.1699 5.22994C10.8099 5.07994 11.4099 5.00997 11.9799 5.00997C16.4699 5.00997 20.1198 8.65998 20.1198 13.15C20.1198 17.64 16.4699 21.2899 11.9799 21.2899C7.48986 21.2899 3.83984 17.64 3.83984 13.15C3.83984 11.41 4.40984 9.72994 5.48984 8.28994C5.73984 7.95994 5.66984 7.48995 5.33984 7.23995C5.00984 6.98995 4.53986 7.05997 4.28986 7.38997C3.00986 9.08997 2.33984 11.08 2.33984 13.15C2.33984 18.46 6.65986 22.7899 11.9799 22.7899C17.2999 22.7899 21.6198 18.47 21.6198 13.15C21.6198 7.82998 17.3098 3.47994 11.9998 3.47994Z" fill="#fff"/>
                        </svg>
                    </button>
                    <button className={isPlaying?"playBtn btn flex flexCenter play":"playBtn btn flex flexCenter"} onClick={()=>togglePlay()}>
                        <span className="playSvg">
                            <svg stroke="currentColor" fill="#fff" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>
                        </span>
                        <span className="pauseSvg">
                            <svg stroke="currentColor" fill="#fff" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"></path></svg>
                        </span>
                    </button>
                    <button className="musicPlayerCloser btn flex flexCenter" onClick={()=>{closePlayer()}}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path></svg>
                    </button>
                </div>
                
            </div>
            <audio className="customMusicPlayer" id="customMusicPlayer" src={activeItem ? activeItem.source : ""} autoPlay>
                
                Your browser does not support the audio element.
            </audio>
        </section>
    </>
}