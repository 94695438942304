import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import { MyComponent, scrollToTop } from "./Methods";
import NewsSwipeList from "./NewsSwipeList";
import "../style/contactus.css";
import {ReactComponent as Phone} from "../icon/phone.svg";
import {ReactComponent as Email} from "../icon/email.svg";

export default function ContactUs() {
    const params = useParams();
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const [formData,setFormData] = useState({
        name:"",
        phone:"",
        subject:"",
        message:""
    });
    const [mainData,setMainData] = useState({
        newsData: null,
        formType: null,
        formMessage : null,
        wrong:false,
    });
    useEffect(() => {
        document.title = "تماس با ما";
        setCity(params.name ? params.name : "melli");
        fetchData();
    }, []);
    function fetchData(){
        scrollToTop();
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("cataNme", "contactus");
        fetch("https://web.beladkhabar.ir/Enrollment/GetPageByName",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            const temp = json[0];
            let tempSelect = "";
            if(temp.typeMenu === "منو ملی"){
                tempSelect = <>
                    <option className="faFont textSize1 textClr3" value="مدیریت">مدیریت</option> 
                    <option className="faFont textSize1 textClr3" value="تبلیغات">تبلیغات</option> 
                    <option className="faFont textSize1 textClr3" value="روابط عمومی">روابط عمومی</option> 
                </>
            }else if(temp.typeMenu === "منو استانی"){
                tempSelect = <>
                    <option className="faFont textSize1 textClr3" value="مدیر روستائی">مدیر روستائی</option> 
                    <option className="faFont textSize1 textClr3" value="مدیر استانی">مدیر استانی</option> 
                    <option className="faFont textSize1 textClr3" value="مدیریت">مدیریت</option> 
                    <option className="faFont textSize1 textClr3" value="تبلیغات">تبلیغات</option> 
                    <option className="faFont textSize1 textClr3" value="روابط عمومی">روابط عمومی</option> 
                </>
            }else {
                tempSelect = <>
                    <option className="faFont textSize1 textClr3" value="مدیر استانی">مدیر استانی</option> 
                    <option className="faFont textSize1 textClr3" value="مدیریت">مدیریت</option> 
                    <option className="faFont textSize1 textClr3" value="تبلیغات">تبلیغات</option> 
                    <option className="faFont textSize1 textClr3" value="روابط عمومی">روابط عمومی</option> 
                </>
            }
            setMainData({
                newsData: temp.page[0],
                formType : tempSelect,
                formMessage:null,
            });
        })
        .catch(() => {
        });
    }
    function submitForm(e){
        e.preventDefault();
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("name", htmlEscape(cityName));
        data.append("subject", htmlEscape(formData.subject));
        data.append("text", htmlEscape(formData.message));
        data.append("fullname", htmlEscape(formData.name));
        data.append("section", "1234567890");
        data.append("cellphone", htmlEscape(formData.phone));
        fetch("https://web.beladkhabar.ir/Enrollment/sendMessage",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            const temp = json[0];
            if(temp.id > 0){
                setMainData({...mainData,formMessage : "پیغام شما با موفقیت ثبت شد",wrong:false});
                setFormData({
                    name:"",
                    phone:"",
                    subject:"",
                    message:""
                });
            }else{
                setMainData({...mainData,formMessage : "در ارسال پیغام خطایی رخ داده است.",wrong:true});
            }
        })
        .catch(() => {
        });

    }
    function htmlEscape(text) {
        return text.replace(/&/g, '&amp;').
          replace(/</g, '&lt;').
          replace(/"/g, '&quot;').
          replace(/'/g, '&#039;');
     }
    return <>
    <section className="grid contactUsGrid container">
        {
            mainData.newsData ? 
            <div className="flex flexCol">
                <div className="flex flexCol newsMainDiv">
                    <h1 className="faFont textSize14 textClr3 pageTitle">
                        {
                            mainData.newsData.title
                        }
                    </h1>
                    {
                        mainData.newsData.conents && <div className="flex flexCol faFont newsContentDiv gap5">
                            {
                                MyComponent({htmlContent:mainData.newsData.conents})
                            }
                        </div>
                    }
                    <div className="flex flexCol contactWayDiv gap10">
                        <p className="faFont textSize1 textClr3">
                            مدیر مسئول : حسن شانجانی
                        </p>
                        <div className="flex flexCol">
                            <a href="tel:+09125583862" className="faFont textSize9 textClr3 flex alignCenter gap10 callItem">
                                <Phone />
                                <span>
                                    شماره تماس :
                                </span>
                                <span>
                                    09125583862
                                </span>
                            </a>
                            <a href="mailto:info@beladkhabar.ir" className="faFont textSize9 textClr3 flex alignCenter gap10 callItem">
                                <Email />
                                <span>
                                    ایمیل :
                                </span>
                                <span>
                                    info@beladkhabar.ir
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <a href="https://eitaa.com/beladkhabar" className="flex flexCol mar20" target="_blank" rel="noreferrer" draggable="false">
                    <img src="/image/socialImage.jpg" alt="socialLink" className="middleImage" />
                </a>
            </div>
            : 
            <div className="flex flexCol newsLoadingDiv rel"></div>
        }
        {
            mainData.formType ? <div className="flex flexCol formDiv">
                <form action="post" className="flex flexCol contactusForm" onSubmit={(e)=>submitForm(e)}>
                    <h2 className="faFont textSize11 textClr3 formTitle">
                        برای تماس با ما فرم زیر را پر کنید.
                    </h2>
                    <fieldset className="flex flexCol">
                        <label htmlFor="name" className="faFont textSize9 textClr3 labelInput">
                            نام و نام خانوادگی
                        </label>
                        <input type="text" id="name" name="name" className="faFont textSize1 textClr3 cutomInput" required placeholder="نام و نام خانوادگی را اینجا وارد کنید." value={formData.name} onChange={(e)=>setFormData({...formData, name : e.target.value })}/>
                    </fieldset>
                    <fieldset className="flex flexCol">
                        <label htmlFor="phone" className="faFont textSize9 textClr3 labelInput" >
                            شماره تماس
                        </label>
                        <input type="tell" id="phone" name="phone" className="faFont textSize1 textClr3 cutomInput" minLength={11} maxLength={11} required placeholder="09123456789" value={formData.phone} onChange={(e)=>setFormData({...formData, phone : e.target.value })}/>
                    </fieldset>
                    <fieldset className="flex flexCol">
                        <label htmlFor="subject" className="faFont textSize9 textClr3 labelInput">
                            ارتباط با
                        </label>
                        <select name="subject" id="subject" className="faFont textSize1 textClr1 customSelect" onChange={(e)=>setFormData({...formData,subject:e.target.value})}>
                            {
                                mainData.formType
                            }
                        </select>
                    </fieldset>
                    <fieldset className="flex flexCol">
                        <label htmlFor="msg" className="faFont textSize9 textClr3 labelInput">
                            ارتباط با
                        </label>
                        <textarea name="msg" id="msg" className="faFont textSize1 textClr3 customTextArea" placeholder="پیغام خود را اینجا بنویسید." value={formData.message} onChange={(e)=>setFormData({...formData, message : e.target.value })}></textarea>
                    </fieldset>
                    <button className="submitBtn faFont textSize1 textClrF flex flexCenter">
                        ثبت
                    </button>
                    {
                        mainData.formMessage && <p className={mainData.wrong ? "faFont textSize9 textClr3 formMessage red" : "faFont textSize9 textClr3 formMessage" }>
                            {
                                mainData.formMessage
                            }
                        </p>
                    }
                </form>
            </div> :
             <div className="flex flexCol loadingForm rel"></div>
        }
    </section>
    {
        mainData.relative && <NewsSwipeList data={{news:mainData.relative}} info={{title:"اخبار مرتبط",hasLocation:false,path:"/"}}/>
    }
</>
}