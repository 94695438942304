import React, { useEffect, useState } from "react";
import '../style/newsList.css';
import NewsItem from "./NewsItem";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../icon/arrowLeft.svg";
export default function NewsList(props) {
    const param = useParams();
    const city = param.name ? param.name : "";
    const info = props.info;
    const data = props.data;
    let loadingItems = [];
    for (let index = 0; index < info.loadingNumber; index++) {
        loadingItems.push(
            <div className="flex flexCol newsLoadingItem rel" key={index}></div>
        );
    }
    return <section className="flex flexCol newsListSection">
            {
                info.hasTitle && data.news && data.news.length > 0 && <div className="flex justifyBetween alignCenter newsListTitleDiv" style={{borderBottom:"2px solid "+info.bg}}>
                    <h2 className="faFont textSize1 textClrF newsListTitleText" style={{backgroundColor:info.bg}}>{info.title}</h2>
                    <Link to={city !== "" ? "/"+city+"/news" : "/news"} className="faFont textSize9 textClr3 flex alignCenter gap5 seeAll">مشاهده آرشیو <LeftArrow /></Link>
                </div>
            }
            {
            !data.news ? <div className="flex flexCol newsListLoadingDiv">
                {
                    loadingItems
                }
            </div> :
            data && data.news && data.news.length === 0 ? "" :
            <div className="flex flexCol newsListContainer">
                <div className="flex flexCol newsList">
                    {
                        data.news.map((item,index)=>{
                            return <NewsItem key={index} info={item}/>
                        })
                    }
                </div>
            </div>

            }
    </section>
}