import React, { useContext, useEffect, useState } from "react";
import '../style/landing.css';
import FullSlider from "./FullSlider";
import LatestNews from "./LatestNews";
import NewsList from "./NewsList";
import AddsList from "./AdsList";
import Time from "./Time";
import MiddleAdds from "./MiddleAds";
import ToggleNews from "./ToggleNews";
import NewsTextList from "./NewsTextList";
import NewPublish from "./NewPublish";
import UserComments from "./UserComments";
import GalleryShow from "./GalleryShow";
import VideoShow from "./VideoShow";
import MapView from "./MapView";
import NewsSwipeList from "./NewsSwipeList";
import ImageNewsList from "./ImageNewsList";
import Radio from "./Radio";
import { useNavigate, useParams } from "react-router-dom";
import { scrollToTop } from "./Methods";
import { AppContext } from "../App";
export default function Landing(){
    const navigate = useNavigate();
    const params = useParams();
    const {currentCity} = useContext(AppContext);
    const [, setCity] = currentCity;
    const [mainData, setMainData] = useState({
        slider:"",
        inFrontOfTheSlideShow: "",
        breakingNews:null,
        notification:null,
        lastNews:null,
        oghatSharei:null,
        ads:null,
        newsTalk:null,
        newsReport:null,
        popularNews:null,
        hotNews:null,
        favorites:null,
        publicationNews:null,
        podcast:null,
        userMessages:null,
        ArticlesAndRuralContent:null,
        photoGallery:null,
        videos:null,
        villages:null,
        newsOfTheProvinces:null,
        constructionNews:null,
        ruralManagementNews:null,
    });
    useEffect(() => {
        document.title = "بلاد خبر || اولین سامانه خبری استانی و روستائی";
        fetchData();
    }, []);
    useEffect(() => {
        setCity(params.name ? params.name : "melli");
        scrollToTop();
        setMainData(
            {
                slider:"",
                inFrontOfTheSlideShow: "",
                breakingNews:null,
                notification:null,
                lastNews:null,
                oghatSharei:null,
                ads:null,
                newsTalk:null,
                newsReport:null,
                popularNews:null,
                hotNews:null,
                favorites:null,
                publicationNews:null,
                podcast:null,
                userMessages:null,
                ArticlesAndRuralContent:null,
                photoGallery:null,
                videos:null,
                villages:null,
                newsOfTheProvinces:null,
                constructionNews:null,
                ruralManagementNews:null,
            }
        );
        fetchData();
    }, [navigate]);
    function fetchData(){
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("password", "1234567890");
        fetch("https://web.beladkhabar.ir/Enrollment/GetFrontPage",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            setMainData(json[0]);
        })
        .catch((err) => {
            navigate("/404");
        });
    }
    return(
    <>
        <FullSlider data={{"sliders":mainData.slider , "banners":mainData.inFrontOfTheSlideShow}}/>
        <LatestNews data={{"news":mainData.breakingNews}}/>
        <ImageNewsList data={{"news":mainData.notification}}/>
        <section className={`grid row1Grid gridRow container mar20 ${mainData.lastNews && mainData.lastNews.length === 0 ? "hideTop" : ""}`}>
            <div className="flex flexCol">
                <NewsList data={{news:mainData.lastNews}} info={{"title":"جدیدترین خبرها","id":"latestNews","bg":"#385E9D","hasTitle":true,"loadingNumber":10}}/>
            </div>
            <div className="flex flexCol row1LeftDiv mar20 gap20">
                <Time data={{time:mainData.oghatSharei }}/>
                <AddsList data={{"ads":mainData.ads}}/>
            </div>
        </section>
        <section className="grid middleAddDiv container gridRow">
            <div className="flex flexCol">
                <a href="https://eitaa.com/beladkhabar" className="flex flexCol" target="_blank" rel="noreferrer">
                    <img src="/image/socialImage.jpg" alt="socialLink" className="middleImage" />
                </a>
                <MiddleAdds data={{"ads":mainData.ads}}/>
                <NewsList data={{news:mainData.newsTalk}} info={{"title":"گفتگوی خبری","id":"converNews","bg":"#5b7bd3","hasTitle":true,"loadingNumber":3}}/>
                <NewsList data={{news:mainData.newsReport}} info={{"title":"گزارش خبری","id":"reportNews","bg":"#5b7bd3","hasTitle":true,"loadingNumber":3}}/>
                <ToggleNews data={{popularNews:mainData.popularNews ,hotNews:mainData.hotNews }} info={{"bg":"#385E9D"}}/>
            </div>
            <div className="flex flexCol row2LeftDiv">
                {
                    mainData.lastNews && mainData.lastNews.length === 0 && <>
                        <div className="flex flexCol row1LeftDiv mar20">
                            <Time data={{time:mainData.oghatSharei }}/>
                            <AddsList data={{"ads":mainData.ads}}/>
                        </div>
                    </>
                }
                <NewsTextList data={{news:mainData.favorites}}/>
                <div className="flex flexCol leftStickList gap10">
                    <NewPublish data={{list:mainData.publicationNews}} info={{"title":"نشریات روستایی","id":"vilageBook"}}/>
                    <NewPublish data={{list:mainData.ArticlesAndRuralContent}} info={{"title":"مقالات و مطالب روستایی","id":"vilageBook"}}/>
                    <Radio data={{list:mainData.podcast}}/>
                </div>
            </div>
        </section>
        <UserComments data={{comments:mainData.userMessages}}/>
        <section className="flex flexCol darkBgSection">
            <div className="flex flexCol container">
                <GalleryShow data={{gallery:mainData.photoGallery}}/>
                <VideoShow data={{videos:mainData.videos,showTitle:true}}/>
            </div>
        </section>
        <MapView data={{villages:mainData.villages}}/>
        <NewsSwipeList data={{news:mainData.newsOfTheProvinces}} info={{title:"اخبار استان‌ها",hasLocation:true}}/>
        <NewsSwipeList data={{news:mainData.constructionNews}} info={{title:"اخبار عمرانی",hasLocation:false}}/>
        <NewsSwipeList data={{news:mainData.ruralManagementNews}} info={{title:"اخبار مدیریت روستایی",hasLocation:false}}/>
    </>
    );
}
