import React, { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import '../style/galleryShow.css';
import {ReactComponent as LeftLogo} from "../icon/arrowLeft.svg";
export default function GalleryShow(props){
    const param = useParams();
    const city = param.name ? param.name : "";
    const data = props.data;
    return <div className="flex flexCol galleryMainDiv">
        <div className="flex alignCenter justifyBetween">
            <h2 className="faFont textSize16 textClrF">
                گالری تصاویر
            </h2>
            <Link to={city !== "" ? "/" + city + "/gallery" : "/gallery"} className="faFont textSize1 textClrF seeAllGallery flex alignCenter gap5">
             مشاهده همه
             <LeftLogo />
             </Link>
        </div>
        {
            data && data.gallery && data.gallery.length > 0 ? 
            <div className="grid galleryGrid">
                <div className="flex flexCol largeGalleryDiv rel">
                    <img src={data.gallery[0].imageAddress} alt={data.gallery[0].title} className="largeGalleryDivImage" />
                    <p className="faFont textSize12 textClrF largeGalleryTitle">
                        {
                            data.gallery[0].title
                        }
                    </p>
                    <Link to={data.gallery[0].url} className="largeGalleryLink">
                        {data.gallery[0].title}
                    </Link>
                </div>
                <div className="flex flexCol smallGalleryList">
                    {
                        data.gallery && data.gallery.map((item,index)=>{
                            return <div className="grid smallGalleryItem" key={index}>
                                <Link to={item.url}>
                                    <img src={item.imageAddress} alt={item.title} className="smallGalleryImage" />
                                </Link>
                                <div className="flex flexCol">
                                    <Link to={item.url} className="faFont textSize11 textClrF">
                                        {item.title}
                                    </Link>
                                    <p className="faFont textSize9 textClrA justifyText">
                                        {
                                            item.description
                                        }
                                    </p>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            :
            <div className="grid galleryLoadingDiv">
                 <div className="mainLoadingGalleryDiv loadingItemDiv rel"></div>
                 <div className="grid listGalleryLoadingDivs">
                    <div className="listGalleryLoadingItem loadingItemDiv rel"></div>
                    <div className="listGalleryLoadingItem loadingItemDiv rel"></div>
                    <div className="listGalleryLoadingItem loadingItemDiv rel"></div>
                 </div>
            </div>
        }
    </div>
}