import React, { useState } from "react";
import '../style/newsList.css';
import NewsList from "./NewsList";
export default function ToggleNews(props){
    const [activePart , setActivePart] = useState("mostComment");
    const info = props.info;
    const mainData = props.data;
    return <div className="flex flexCol toggleNewsSection">
        <div className="flex alignCenter newsListTitleDiv" style={{borderBottom:"2px solid "+info.bg}}>
            {
                mainData.hotNews &&  mainData.hotNews.length > 0 &&
                <button className={activePart === "mostComment" ? "faFont textSize1 textClrF toggleNewsBtn activeBtn" : "faFont textSize1 textClr3 toggleNewsBtn"} onClick={()=>setActivePart("mostComment")} >پر بحث ترین</button>
            }
            {
                mainData.popularNews && mainData.popularNews.length > 0 &&
                <button className={activePart !== "mostComment" ? "faFont textSize1 textClrF toggleNewsBtn activeBtn" : "faFont textSize1 textClr3 toggleNewsBtn"} onClick={()=>setActivePart("mostView")} >بیشترین بازدید</button>
            }
        </div>
        {
           <div className={activePart === "mostComment" ? "flex flexCol toggleNewsList" : "flex flexCol toggleNewsList hide"}>
                <NewsList data={{news:mainData.hotNews}} info={{"title":"پر‌بحث‌ترین","id":"mostComment","bg":"#5b7bd3","hasTitle":false}}/>
           </div>
        }
        {
            <div className={activePart !== "mostComment" ? "flex flexCol toggleNewsList" : "flex flexCol toggleNewsList hide"}>
                <NewsList data={{news:mainData.popularNews}} info={{"title":"بیشترین بازدید","id":"mostView","bg":"#5b7bd3","hasTitle":false}}/>
           </div>
        }
    </div>
}