import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { Link, useLocation, useParams } from "react-router-dom";
import "../style/newsPage.css";
import NewsList from "./NewsList";
import AddsList from "./AdsList";

import PagePagintaion from "./PagePagination";
import { scrollToTop } from "./Methods";
export default function NewsPage(props) {
    const intitalData = props.data;
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const {currentCity} = useContext(AppContext);
    const [city,setCity] = currentCity;
    const pageNumber = useRef(1);
    const firstLoad = useRef(true);
    const currentPage = useRef(intitalData.pageCat);
    const [mainData,setMainData] = useState({
        ads:null,
        newsList : null,
        totalPage : null,
        persianName : null,
        loading :false,
    });
    useEffect(() => {
        document.title = intitalData.title 
        setCity(params.name ? params.name : "melli");
        fetchData();
        if(!firstLoad.current){
            setMainData({
                ads:null,
                newsList : null,
                totalPage : null,
                persianName : null,
                loading :false
            });
        }
    }, [intitalData]);
    useEffect(() => {
        if(!firstLoad.current){
            if(intitalData.pageCat !== currentPage.current){
                currentPage.current = intitalData.pageCat;
            }else{
                document.title = intitalData.title 
                fetchData();
                setMainData({
                    ads:null,
                    newsList : null,
                    totalPage : null,
                    persianName : null
                });
            }
        }
    }, [params]);
    function fetchData(){
        pageNumber.current = queryParams.get("p") ? queryParams.get("p") : 1;
        scrollToTop();
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("password", "1234567890");
        data.append("categoryName", intitalData.pageCat);
        data.append("pageIndex",pageNumber.current);
        data.append("pageCount",10);
        fetch("https://web.beladkhabar.ir/Enrollment/GetpagesByCategoryName",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            const tempData = json[0];
            const counts = tempData.pages[0].recordCounts ? tempData.pages[0].recordCounts/ 10 : 1 ;
            setMainData({
                ads:tempData.ads,
                newsList:tempData.pages[0].pages,
                totalPage : counts,
                persianName : tempData.persianName,
                loading : false,
            });
            firstLoad.current = false;
            document.title = intitalData.title + " " + (tempData.persianName === "ملی" ? "" : tempData.persianName);
        })
        .catch((err) => {
            setMainData({...mainData,loading : false})
        });
    }
    function changePage(newPageNumber){
        setMainData({...mainData,loading : true});
        queryParams.set("p",newPageNumber);
        window.history.pushState({},"",`${window.location.pathname}?${queryParams}`);
        fetchData();
    }
    return <>
         <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>)
                }
                <p className="faFont textSize9 textClr3">
                {
                    intitalData.title
                }
                </p>
            </div>
            <div className="grid newListGrid">
                <div className="flex flexCol">
                    <NewsList data={{news:mainData.newsList}} info={{"title":"جدیدترین خبرها","id":"latestNews","bg":"#385E9D","hasTitle":false,"loadingNumber":10}}/>
                    <PagePagintaion data={{totalNumber:mainData.totalPage,
                    changePage : changePage,
                    currentPage:pageNumber}} />
                    {
                        mainData.newsList && mainData.loading ? <div className="flex flexCol fetchingDataDiv flexCenter">
                            <div className="flex flexCenter flexCol loadingParentDiv">
                            <div className="flex fetchingLoadingItem"></div>
                            </div>
                        </div> : ""
                    }
                </div>
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
    </>
}