import React, { useEffect, useState } from "react";
import '../style/newsTextList.css';
import Loading from "./Loading";
import { Link } from "react-router-dom";

export default function NewsTextList(props){
    const data = props.data;
    return <section className="flex flexCol newsTextListContanier">
        {
        !data.news ? <div className="flex flexCenter newsTextListLoadingDiv"><Loading /></div> :
        data && data.news && data.news.length === 0 ? "" :
        <div className="flex flexCol">
            <div className="flex justifyBetween alignCenter newsListTitleDiv" style={{borderBottom:"2px solid #1e88e5"}}>
                <h3 className="faFont textSize1 textClrF newsTextTitle">برگزیده ها</h3>
            </div>
            <div className="flex flexCol newsTextList customScrollbar">
                {
                    data.news.map((item,index)=>{
                        return <Link to={item.link} className="faFont textSize9 textClr3 rel newTextLink flex alignCenter" key={index} target="_blank">
                            <p className="faFont">{item.title}</p>
                        </Link>
                    })
                }
            </div>
        </div>
        }
    </section>
}