import { createContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import About from "./Component/About";
import NewsPage from "./Component/NewsPage";
import CityDetails from "./Component/CityDetails";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import FamousDetails from "./Component/FamousDetails";
import { useState } from "react";
import Landing from "./Component/Landing";
import PostDetails from "./Component/PostDetails";
import ContactUs from "./Component/ContactUs";
import GridPage from "./Component/GridPage";
import Video from "./Component/Video";
import Gallery from "./Component/Gallery";
import PoyeshAndArticleDetails from "./Component/PoyeshAndArticleDetails";
import Notification from "./Component/Notifcation";
import PodcastList from "./Component/PodcastList";
import SubNewsList from "./Component/SubNewsList";
import Faq from "./Component/Faq";
import NotFound from "./Component/NotFound";
export const AppContext = createContext();
function App() {
  const [city, setCity] = useState("");
  return (
    <>
      <AppContext.Provider
        value={{
          currentCity: [city, setCity],
        }}
      >
        <BrowserRouter>
          <Header />
          <Routes>
            <Route index path="/" element={<Landing />}></Route>
            <Route path="/:name" element={<Landing />}></Route>
            <Route
              path="/:name/famous"
              element={<CityDetails data={{ pageName: "famous" }} />}
            ></Route>
            <Route path="/:name/famous/:id" element={<FamousDetails />}></Route>
            <Route
              path="/:name/obituary"
              element={<CityDetails data={{ pageName: "obituary" }} />}
            ></Route>
            <Route
              path="/:name/excellentStudents"
              element={<CityDetails data={{ pageName: "excellentStudents" }} />}
            ></Route>
            <Route
              path="/:name/Requirements"
              element={<CityDetails data={{ pageName: "Requirements" }} />}
            ></Route>
            <Route
              path="/:name/Introducingjobsandbusinesses"
              element={
                <CityDetails
                  data={{ pageName: "Introducingjobsandbusinesses" }}
                />
              }
            ></Route>
            <Route
              path="/aboutus"
              element={<About data={{ pageName: "aboutus" }} />}
            ></Route>
            <Route
              path="/:name/aboutus"
              element={<About data={{ pageName: "aboutus" }} />}
            ></Route>
            <Route
              path="/rulesAndRegulations"
              element={<About data={{ pageName: "rulesAndRegulations" }} />}
            ></Route>
            <Route
              path="/:دشپث/rulesAndRegulations"
              element={<About data={{ pageName: "rulesAndRegulations" }} />}
            ></Route>
            <Route path="/contactus" element={<ContactUs />}></Route>
            <Route path="/:name/contactus" element={<ContactUs />}></Route>
            <Route
              path="/:name/economic"
              element={
                <NewsPage
                  data={{ pageCat: "economic", title: " اخبار اقتصادی" }}
                />
              }
            ></Route>
            <Route
              path="/economic"
              element={
                <NewsPage
                  data={{ pageCat: "economic", title: " اخبار اقتصادی" }}
                />
              }
            ></Route>
            <Route
              path="/:name/construction"
              element={
                <NewsPage
                  data={{ pageCat: "construction", title: " اخبار عمرانی" }}
                />
              }
            ></Route>
            <Route
              path="/construction"
              element={
                <NewsPage
                  data={{ pageCat: "construction", title: " اخبار عمرانی" }}
                />
              }
            ></Route>
            <Route
              path="/:name/social"
              element={
                <NewsPage
                  data={{ pageCat: "social", title: " اخبار اجتماعی" }}
                />
              }
            ></Route>
            <Route
              path="/social"
              element={
                <NewsPage
                  data={{ pageCat: "social", title: " اخبار اجتماعی" }}
                />
              }
            ></Route>
            <Route
              path="/:name/rural-management"
              element={
                <NewsPage
                  data={{
                    pageCat: "rural-management",
                    title: "اخبار مدیریت روستایی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/rural-management"
              element={
                <NewsPage
                  data={{
                    pageCat: "rural-management",
                    title: "اخبار مدیریت روستایی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/rural-tourism"
              element={
                <NewsPage
                  data={{
                    pageCat: "rural-tourism",
                    title: "اخبار گردشگری روستایی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/rural-tourism"
              element={
                <NewsPage
                  data={{
                    pageCat: "rural-tourism",
                    title: "اخبار گردشگری روستایی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/exercise-and-vitality"
              element={
                <NewsPage
                  data={{
                    pageCat: "exercise-and-vitality",
                    title: "اخبار ورزش و نشاط",
                  }}
                />
              }
            ></Route>
            <Route
              path="/exercise-and-vitality"
              element={
                <NewsPage
                  data={{
                    pageCat: "exercise-and-vitality",
                    title: "اخبار ورزش و نشاط",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/news"
              element={
                <NewsPage
                  data={{
                    pageCat: "news",
                    title: "آخرین اخبار",
                  }}
                />
              }
            ></Route>
            <Route
              path="/news"
              element={
                <NewsPage
                  data={{
                    pageCat: "news",
                    title: "آخرین اخبار",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/newsoftheprovinces"
              element={
                <NewsPage
                  data={{
                    pageCat: "newsoftheprovinces",
                    title: "اخبار استان ها",
                  }}
                />
              }
            ></Route>
            <Route
              path="/newsoftheprovinces"
              element={
                <NewsPage
                  data={{
                    pageCat: "newsoftheprovinces",
                    title: "اخبار استان ها",
                  }}
                />
              }
            ></Route>
            <Route path="/post/:id" element={<PostDetails />}></Route>
            <Route path="/:name/post/:id" element={<PostDetails />}></Route>
            <Route
              path="/:name/poyesh/:id"
              element={
                <GridPage
                  data={{
                    pageName: "Poyesh",
                    pageTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/poyesh/:id"
              element={
                <GridPage
                  data={{
                    pageName: "Poyesh",
                    pageTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/poyesh/"
              element={
                <GridPage
                  data={{
                    pageName: "Poyesh",
                    pageTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/poyesh"
              element={
                <GridPage
                  data={{
                    pageName: "Poyesh",
                    pageTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/articlesandruralcontent/"
              element={
                <GridPage
                  data={{
                    pageName: "articlesandruralcontent",
                    pageTitle: "مقالات و نشرییات روستایی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/articlesandruralcontent"
              element={
                <GridPage
                  data={{
                    pageName: "articlesandruralcontent",
                    pageTitle: "مقالات و نشرییات روستایی",
                  }}
                />
              }
            ></Route>
            <Route
              path="/videos/"
              element={
                <GridPage
                  data={{
                    pageName: "Videos",
                    pageTitle: "ویدیو ها",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/videos"
              element={
                <GridPage
                  data={{
                    pageName: "Videos",
                    pageTitle: "ویدیو ها",
                  }}
                />
              }
            ></Route>
            <Route
              path="/gallery/"
              element={
                <GridPage
                  data={{
                    pageName: "Galleries",
                    pageTitle: "گالری تصاویر",
                  }}
                />
              }
            ></Route>
            <Route
              path="/:name/gallery"
              element={
                <GridPage
                  data={{
                    pageName: "Galleries",
                    pageTitle: "گالری تصاویر",
                  }}
                />
              }
            ></Route>
            <Route path="/:name/video/:id" element={<Video />} />
            <Route path="/video/:id" element={<Video />} />
            <Route path="/:name/video/" element={<Video />} />
            <Route path="/video/" element={<Video />} />
            <Route path="/:name/album/:id" element={<Gallery />} />
            <Route path="/album/:id" element={<Gallery />} />
            <Route path="/:name/album/" element={<Gallery />} />
            <Route path="/album/" element={<Gallery />} />
            <Route
              path="/:name/poyeshpage/:id"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "poyeshpage",
                    tableClass: "gRow3",
                    parentLink: "/poyesh",
                    parentTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            />
            <Route
              path="/poyeshpage/:id"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "poyeshpage",
                    tableClass: "gRow3",
                    parentLink: "/poyesh",
                    parentTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            />
            <Route
              path="/:name/poyeshpage/"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "poyeshpage",
                    tableClass: "gRow3",
                    parentLink: "/poyesh",
                    parentTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            />
            <Route
              path="/poyeshpage/"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "poyeshpage",
                    tableClass: "gRow3",
                    parentLink: "/poyesh",
                    parentTitle: "پویش شنبه های آموزشی",
                  }}
                />
              }
            />
            <Route
              path="/:name/articlesandruralcontentpage/:id"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "articlesandruralcontentpage",
                    tableClass: "gRow3",
                    parentLink: "/articlesandruralcontent",
                    parentTitle: "مقالات و نشریات روستایی",
                  }}
                />
              }
            />
            <Route
              path="/articlesandruralcontentpage/:id"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "articlesandruralcontentpage",
                    tableClass: "gRow3",
                    parentLink: "/articlesandruralcontent",
                    parentTitle: "مقالات و نشریات روستایی",
                  }}
                />
              }
            />
            <Route
              path="/:name/articlesandruralcontentpage/"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "articlesandruralcontentpage",
                    tableClass: "gRow3",
                    parentLink: "/articlesandruralcontent",
                    parentTitle: "مقالات و نشریات روستایی",
                  }}
                />
              }
            />
            <Route
              path="/articlesandruralcontentpage/"
              element={
                <PoyeshAndArticleDetails
                  data={{
                    pageName: "articlesandruralcontentpage",
                    tableClass: "gRow3",
                    parentLink: "/articlesandruralcontent",
                    parentTitle: "مقالات و نشریات روستایی",
                  }}
                />
              }
            />
            <Route path="/notifications/" element={<Notification />} />
            <Route path="/podcast/" element={<PodcastList />} />
            <Route path="/:name/podcast/" element={<PodcastList />} />
            <Route
              path="/:name/villageManagementPerformanceReport/"
              element={
                <SubNewsList
                  data={{
                    pageName: "villageManagementPerformanceReport",
                    pageTitle: "گزارش عملکرد مدیریت روستایی",
                  }}
                />
              }
            />
            <Route
              path="/villageManagementPerformanceReport/"
              element={
                <SubNewsList
                  data={{
                    pageName: "villageManagementPerformanceReport",
                    pageTitle: "گزارش عملکرد مدیریت روستایی",
                  }}
                />
              }
            />
            <Route
              path="/:name/villageProductionStatistics/"
              element={
                <SubNewsList
                  data={{
                    pageName: "villageProductionStatistics",
                    pageTitle: "آمار تولیدات روستایی",
                  }}
                />
              }
            />
            <Route
              path="/villageProductionStatistics/"
              element={
                <SubNewsList
                  data={{
                    pageName: "villageProductionStatistics",
                    pageTitle: "آمار تولیدات روستایی",
                  }}
                />
              }
            />
            <Route
              path="/:name/villagesWithVillages/"
              element={
                <SubNewsList
                  data={{
                    pageName: "villagesWithVillages",
                    pageTitle: "لیست روستاهای دارای دهیاری",
                  }}
                />
              }
            />
            <Route
              path="/villagesWithVillages/"
              element={
                <SubNewsList
                  data={{
                    pageName: "villagesWithVillages",
                    pageTitle: "لیست روستاهای دارای دهیاری",
                  }}
                />
              }
            />
            <Route path="/:name/faq/" element={<Faq />} />
            <Route path="/faq/" element={<Faq />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" component={<NotFound />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AppContext.Provider>
    </>
  );
}

export default App;
