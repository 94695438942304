import React, { useEffect, useState } from "react";
import '../style/newPublish.css';

export default function NewPublish(props){
    const info = props.info;
    const data = props.data;
    return <>
        {
            data && data.list && data.list.length > 0 ? 
            <div className="flex flexCol newPublishContainer">
                <div className="flex alignCenter partTitleDiv" style={{borderBottom: "2px solid #1e88e5"}}>
                    <p className="faFont textSize1 textClrF partTitle" style={{backgroundColor:"#1e88e5"}}>{info.title}</p>
                </div>
                <div className="flex flexCol newPublishList mar10">
                    {
                        data.list.map((item,index)=>{
                            return <a href={item.link} className="newPublishLink" key={index}>
                                <img src={item.src} alt="تازه های نشر" className="newPublishImage"/>
                            </a>
                        })
                    }
                </div>
            </div>
            : ""
        }
    </>
}