import { Link } from 'react-router-dom';
import '../style/header.css';
import { useContext, useEffect, useRef, useState } from 'react';
import {ReactComponent as MenuLogo } from '../icon/menu.svg';
import {ReactComponent as SearchLogo } from '../icon/search.svg';
import {ReactComponent as CloseLogo } from '../icon/close.svg';
import {ReactComponent as ArrowDownLogo } from '../icon/arrowDown.svg';
import Loading from './Loading';
import { AppContext } from '../App';
import { timeInShamsi } from './Methods';
const cityPerName = {
    "alborz":"البرز",
    "ardabil":"اردبیل",
    "azerbaijan-east":"آذربایجان‌شرقی",
    "azerbaijan-west":"آذربایجان‌غربی",
    "bushehr":"بوشهر",
    "chahar-mahaal-bakhtiari":"چهارمحال‌بختیاری",
    "fars":"فارس",
    "gilan":"گیلان",
    "golestan":"گلستان",
    "hamadan":"همدان",
    "hormozgan":"هرمزگان",
    "ilam":"ایلام",
    "isfahan":"اصفهان",
    "kerman":"کرمان",
    "kermanshah":"کرمانشاه",
    "khorasan-north":"خراسان‌شمالی",
    "khorasan-razavi":"خراسان‌رضوی",
    "khorasan-south":"خراسان‌جنوبی",
    "khuzestan":"خوزستان",
    "kohgiluyeh-boyer-ahmad":"کهگیلویه‌و‌بویر‌احمد",
    "kurdistan":"کردستان",
    "lorestan":"لرستان",
    "markazi":"مرکزی",
    "mazandaran":"مازندران",
    "qazvin":"قزوین",
    "qom":"قم",
    "semnan":"سمنان",
    "sistan-baluchestan":"سیستان‌و‌بلوچستان",
    "tehran":"تهران",
    "yazd":"یزد",
    "zanjan":"زنجان"
};
export default function Header() {
    const [navigationBar,setNavigationBar] = useState(false);
    const [stateToggle,setStateToggle] = useState(false);
    const [vilageToggle,setVilageToggle] = useState(false);
    const {currentCity} = useContext(AppContext);
    const [city,] = currentCity;
    const cityInPer = useRef("");
    const [mainData,setMainData] = useState({
        village : null,
        middleAds : null,
        category : null,
        province : null,
        topImage : null,
        topImageUrl : "",
        middleAdsUrl : "",
        typeMenu : null,
        minTopImage : ""
    });
    useEffect(()=>{
       setMainData(
        {
            ...mainData,
            village : null,
            category : null,
            province : null,
            typeMenu : null,
        }
        );
        fetchData();
    },[city]);
    function fetchData(){
        if(city === ""){
            return;
        }
        const data = new FormData();
        data.append("name", city);
        data.append("password", "1234567890");
        fetch("https://web.beladkhabar.ir/Enrollment/GetHeader",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            let tmpData = json[0];
            if(tmpData && tmpData.typeMenu === "منو روستایی" && tmpData.village){
                tmpData.village.forEach(element => {
                    if(element.latinName === city){
                        cityInPer.current = element.title;
                    }
                });
            }else if(tmpData && tmpData.typeMenu === "منو استانی"){
                cityInPer.current = cityPerName[city];
            }
            
            setMainData(json[0]);
        })
        .catch((err) => {
        });
        setNavigationBar(false);
    }
    useEffect(() => {
        const headerDesktopLogoDiv = document.querySelector(".headerDesktopLogoDiv");
        window.addEventListener("scroll", () => {
            let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            if(winScroll < 200 && headerDesktopLogoDiv.classList.contains("active")){
                headerDesktopLogoDiv && headerDesktopLogoDiv.classList.remove("active");
            }else if(winScroll > 200 && !headerDesktopLogoDiv.classList.contains("active")){
                headerDesktopLogoDiv && headerDesktopLogoDiv.classList.add("active");
            }
        });
    }, []);
    function getToday(){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = timeInShamsi(yyyy,mm,dd);
        return today;
    }
    return <>
        <div className="flex flexCol headerRow1">
            {
             mainData.topImage && <a className="flex flexCol" draggable="false" href={mainData.topImageUrl} >
                         <img src={mainData.topImage} alt="" className="bannerMainImage" />
                        <img src={mainData.minTopImage} alt="" className="bannerMinImage" />
                </a>
            }
        </div>
        <header className="flex flexCol">
            <div className="headerRow2 flex alignCenter justifyBetween">
                <div className="flex gap10 headerRow2RightDiv">
                    <Link to={"/"} >
                        <img src="/image/logo.png" alt="" className='headerMainLogo' height={80}/>
                    </Link>
                </div>
                <div className="flex flexCol centerHeaderBanner">
                    {
                       mainData.middleAds && <Link to={mainData.middleAdsUrl}>
                        <img src={mainData.middleAds} alt="header banner" className='headerBanner' height={60}/> 
                       </Link>
                    }
                </div>
                <button className="menuBtn iconBtn flex flexCenter textSize1" onClick={ ()=> setNavigationBar(!navigationBar)}>
                    <span className="hideText">menu</span>
                    <MenuLogo />
                </button>
                <div className="flex flexCol headerLeftSideDiv spaceBetween">
                    <div className="flex alignCenter gap10 spaceBetween">
                        <p className="currentDate faFont textSize1 textClrF">
                        {
                            getToday()
                        }
                        </p>
                        <div className="flex alignCenter gap5">
                            {
                                mainData.socialNetwork && mainData.socialNetwork.map((item,index)=>{
                                    return <a href={item.url} key={index} className="flex flexCenter socialHeaderItem textClr5">
                                    <i title="l-1" className={item.src}></i>
                                    <span className="hideText">{item.title}</span>
                                </a>
                                })
                            }
                        </div>
                    </div>
                    <div className="headerSearchDiv flex flexCol">
                        <form method='get' action='/search' className="flex flexCol navSearchForm rel">
                            <input type="text" className='faFont textSize9 textClr3 customInput headerSearchinput' name='q' placeholder='جستجو کنید ...'/>
                            <button className="flex flexCenter navSearchSubmitBtn iconBtn">
                                <SearchLogo />
                                <span className="hideText">Search</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </header>
        <section  className="desktopHeaderSection flex alignCenter rel">
        {
        <>
            <Link to={"/"} className="headerDesktopLogoDiv">
                <img src="/image/logo.png" alt="" className='headerDisktopLogo' height={40}/>
            </Link>
            {
                mainData.typeMenu === 'منو روستایی' && <Link to={"/"+mainData.parentLatinName} className='faFont textSize9 textClr3 desktopHeaderItem'>
                    {
                        mainData.parentPersianName
                    }
                </Link>
            }
            {
                city && city !== "melli" && 
                <Link to={"/"+city} className='faFont textSize9 textClr3 desktopHeaderItem' id='currentCity'>
                    {
                        cityInPer.current
                    }
                </Link>
            }
            {
                mainData.category && mainData.category.map((item,index)=>{
                    return <Link to={item.link} key={index} className='faFont textSize9 textClr3 desktopHeaderItem'>
                           {
                            item.title
                           }
                        </Link> 
                })
            }
            {
                mainData.province && <div className='grid desktopMegaHolderDiv'>
                    <p className="faFont textSize9 textClr3 desktopHeaderItem" >استان‌ها</p>
                    <div className="flex flexCol desktopMegaMainDiv">
                        <div className="flex flexCol desktopMegaMainDiv2">
                            {
                                mainData.province.map((item,index)=>{
                                    return <Link to={"/"+item.latinName} key={index} className='faFont textSize9 textClr3 desktopHeaderItem'>
                                            {
                                                item.title
                                            }
                                        </Link> 
                                })
                            }
                        </div>
                    </div>
                </div>
            }
            {
                mainData.village && <div className='grid desktopMegaHolderDiv'>
                    <p className="faFont textSize9 textClr3 desktopHeaderItem">روستاها</p>
                    <div className="flex flexCol desktopMegaMainDiv">
                        <div className="flex flexCol desktopMegaMainDiv2">
                        {
                            mainData.village.map((item,index)=>{
                                return <Link to={"/"+item.latinName} key={index} className='faFont textSize9 textClr3 desktopHeaderItem flex alignCenter gap5'>
                                        {
                                            item.title
                                        }
                                        <span className="textClr7 textSize9">{" (" + item.provincePersianName + ")"}</span>
                                    </Link> 
                            })
                        }
                        </div>
                    </div>
                </div>
            }
        </>
        }
        </section>
        <div className={navigationBar ? "navigationCloser active" : "navigationCloser" } onClick={ ()=> setNavigationBar(!navigationBar)}></div>
        <nav className={navigationBar ? "navigation flex flexCol active" : "navigation flex flexCol" }>
            <div className="grid navMainDiv rel">
                <button className="closeNavBtn flex flexCenter iconBtn" onClick={ ()=> setNavigationBar(!navigationBar)}>
                    <CloseLogo />
                <span className="hideText">close</span>
                </button>
                <div className="flex flexCol navSearchDiv">
                    <form method='get' className="flex flexCol navSearchForm rel">
                        <input type="text" className='faFont textSize9 textClr3 navSearchInput customInput' name='q' placeholder='جستجو کنید ...'/>
                        <button className="flex flexCenter navSearchSubmitBtn iconBtn">
                            <SearchLogo />
                            <span className="hideText">Search</span>
                        </button>
                    </form>
                </div>
                {
                    mainData.category ? <div className="flex flexCol navLinkList customScrollbar">
                        {
                            mainData.typeMenu === 'منو روستایی' && <Link to={"/"+mainData.parentLatinName} className='faFont textSize9 textClr3 navLinkItem'>
                                {
                                    mainData.parentPersianName
                                }
                            </Link>
                        }
                        {
                            city && city !== "melli" && 
                            <Link to={"/"+city} className='faFont textSize9 textClr3 navLinkItem'>
                                {
                                    cityInPer.current
                                }
                            </Link>
                        }
                    {
                        
                        mainData.category && mainData.category.map((item,index)=>{
                            return <Link target='_blank' to={item.link} key={index} className='faFont textSize9 textClr3 navLinkItem'>
                                {
                                    item.title
                                }
                            </Link> 
                        })
                    }
                    <Link to={mainData.about} className='faFont textSize9 textClr3 navLinkItem'>
                        درباره ما
                    </Link> 
                    <Link to={mainData.contact} className='faFont textSize9 textClr3 navLinkItem'>
                        تماس با ما
                    </Link> 
                    {
                        mainData.province && <div className='flex flexCol'>
                            <button className={stateToggle ? "toggleBtn faFont textSize9 textClr3 stateToggleBtn rel navLinkItem toggle" : "toggleBtn faFont textSize9 textClr3 stateToggleBtn rel navLinkItem" } onClick={ ()=> setStateToggle(!stateToggle)}>استان ها
                                <span className="logoSpan flex flexCenter">
                                    <ArrowDownLogo/>
                                </span>
                            </button>
                            <div className="flex flexCol navStateList">
                            {
                                mainData.province.map((item,index)=>{
                                    return <Link to={"/"+item.latinName} key={index} className='faFont textSize9 textClr3 navLinkItem' target='_blank'>
                                        {
                                            item.title
                                        }
                                    </Link>
                                })
                            }
                            </div>
                        </div>
                    }
                    {
                        mainData.village && <div className='flex flexCol'>
                            <button className={vilageToggle ? "toggleBtn faFont textSize9 textClr3 stateToggleBtn rel navLinkItem toggle" : "toggleBtn faFont textSize9 textClr3 stateToggleBtn rel navLinkItem" } onClick={ ()=> setVilageToggle(!vilageToggle)}>روستاها
                                <span className="logoSpan flex flexCenter">
                                    <ArrowDownLogo/>
                                </span>
                            </button>
                            <div className="flex flexCol navStateList">
                            {
                                mainData.village.map((item,index)=>{
                                    return <Link to={"/"+item.latinName} key={index} className='faFont textSize9 textClr3 navLinkItem' target='_blank'>
                                            {
                                                item.title
                                            }
                                        </Link> 
                                })
                            }
                            </div>
                        </div>
                    }
                    </div>
                    :
                    <div className="flex flexCenter flexCol">
                        <Loading />
                    </div>
                }
                <div className="flex socialList">
                    {
                        mainData.socialNetwork && mainData.socialNetwork.map((item,index)=>{
                            return <a href={item.url} key={index} className="flex flexCenter headerSocialItem textClr5">
                            <i title="l-1" className={item.src}></i>
                            <span className="hideText">{item.title}</span>
                        </a>
                        })
                    }   
                </div>
            </div>
        </nav>
    </>
}