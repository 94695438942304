import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import { MyComponent, scrollToTop } from "./Methods";
import AddsList from "./AdsList";
import NewsSwipeList from "./NewsSwipeList";
import "../style/postDetails.css";
export default function About(props) {
    const {pageName} = props.data;
    const params = useParams();
    const {cname} = params;
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const [mainData,setMainData] = useState({
        ads:null,
        newsData : null,
        relative : null,
    });
    useEffect(() => {
        setCity(params.name ? params.name : "melli");
        fetchData();
    }, [pageName]);
    function fetchData(){
        scrollToTop();
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("cataNme", pageName);
        fetch("https://web.beladkhabar.ir/Enrollment/GetPageByName",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            const temp = json[0];
            if(temp.page && temp.page[0]){
                document.title = temp.page[0].title;
            }
            setMainData({
                newsData : temp.page[0],
                ads:temp.ads,
                relative : temp.relatedNews
            });
        })
        .catch(() => {
        });
    }
    return <>
    <section className="grid mainDivGrid container">
    {
        mainData.newsData ? 
        <div className="flex flexCol">
            <div className="flex flexCol newsMainDiv">
                <h1 className="faFont textSize14 textClr3 pageTitle">
                    {
                        mainData.newsData.title
                    }
                </h1>
                {
                    mainData.newsData.conents && <div className="flex flexCol faFont newsContentDiv gap5">
                        {
                            MyComponent({htmlContent:mainData.newsData.conents})
                        }
                    </div>
                }
            </div>
            <a href="https://eitaa.com/beladkhabar" className="flex flexCol mar20" target="_blank" rel="noreferrer" draggable="false">
                <img src="/image/socialImage.jpg" alt="socialLink" className="middleImage" />
            </a>
        </div>
        : 
        <div className="flex flexCol newsLoadingDiv rel"></div>
    }
        <div className="flex flexCol row1LeftDiv">
            <AddsList data={{"ads":mainData.ads}}/>
        </div>
    </section>
    {
        mainData.relative && <NewsSwipeList data={{news:mainData.relative}} info={{title:"اخبار مرتبط",hasLocation:false,path:"/"}}/>
    }
</>
}