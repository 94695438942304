import React from "react";
import '../style/latestNews.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper/modules";
import 'swiper/css';
import '../style/imageNewslist.css';
import { Link } from "react-router-dom";
export default function ImageNewsList(props){
    const data = props.data;
    let newsList = data.news;
    if(data.news){
        if(data.news.length <= 6){
            newsList = data.news.concat(data.news);
        }
    }   
    return <>
    {
        data.news && data.news.length > 0 ? <section className="flex flexCol imageNewsSection">
            {
               <div className="flex flexCol container ">
                <Swiper className="swiper latestSliderSwiper rel"
                    modules={[Autoplay,Navigation]}
                    spaceBetween={20}
                    slidesPerView={2}
                    loop={true}
                    navigation={{
                        prevEl: '.prevSlide',
                        nextEl: '.nextSlide',
                    }}
                    autoplay={{
                        delay: 2500,
                    }}
                    breakpoints={{
                        649: {
                          slidesPerView: 3,
                          autoplay: false
                        },
                        768:{
                          slidesPerView: 5,
                          autoplay: false
                        },
                        968:{
                          slidesPerView: 6,
                          autoplay: false
                        }
                    }}
                    >
                    {
                        newsList.map((item,index)=>{
                            return <SwiperSlide key={index}>
                                <div className="flex flexCol silderItem rel" >
                                    <img src={item.src} alt={item.link} className="latestNewsSliderImage" />
                                    <Link to={item.link} className="hideText latestNewsLink">{item.link}</Link>
                                </div>
                            </SwiperSlide>
                        })
                    }
                </Swiper>
               </div>
            }
        </section> : <div className="flex alignCenter imageNewsListLoading hideScrollBar">
            <div className="flex alignCenter container">
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
                <div className="imageNewsLoadingDiv rel"></div>
            </div>
        </div>
    }   
    </>
}