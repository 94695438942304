import React, { useContext, useEffect, useRef, useState } from "react";
import AddsList from "./AdsList";
import { AppContext } from "../App";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style/tableList.css";
import {ReactComponent as DownloadIcon} from "../icon/download.svg";
import PagePagintaion from "./PagePagination";
import { scrollToTop } from "./Methods";
export default function Notification(){
    const navigate = useNavigate();
    const params = useParams();
    const {cname} = params;
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentPage = useRef(1);

    const [mainData,setMainData] = useState({
        ads: null,
        data : null,
        persianName : null,
        totalNumber : 0,
        pageTitle : "",
        loading :false
    });
    
    useEffect(() => {
        setCity(cname ? cname : "melli");
        fetchData();
    }, []);
    function fetchData(){
        scrollToTop();
        currentPage.current = queryParams.get("p") ? queryParams.get("p") : 1;
        const cityName = cname ? cname : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("cid",0);
        data.append("pageCount",12);
        data.append("pageIndex",currentPage.current);
        data.append("stateName","notifications")
        fetch("https://web.beladkhabar.ir/Enrollment/getpn",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(json.msg);
            }
            const tempData = json[0];
            setMainData({
                ads: tempData.ads,
                data : tempData.dt[0].notifications,
                persianName : tempData.persianName,
                totalNumber : Math.ceil(tempData.dt[0].count / 12) ,
                pageTitle : tempData.title,
                loading : false,
            });
            document.title = "ابلاغیه های روستایی";
        })
        .catch((err) => {
            setMainData({...mainData,loading : false})
        });
    }
    function changePage(newPage){
        setMainData({...mainData,loading : true});
        queryParams.set("p",newPage);
        window.history.pushState({},"",`${window.location.pathname}?${queryParams}`);
        fetchData();
    }
    return <>
        <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>)
                }
                <p className="faFont textSize9 textClr3">
                    ابلاغیه های روستایی
                </p>
            </div>
            <div className="grid tableListGrid">
                <div className="flex flexCol rel">
                    {
                        mainData.data ? <div className="flex flexCol tableListContainer">
                            <h1 className="faFont textSize12 textClr3 tableTitle">
                                ابلاغیه های روستایی
                            </h1>
                            <div className={`flex flexCol notifTitleDiv`}>
                                <p className="faFont textSize9 textClr3">عنوان</p>
                                <p className="faFont textSize9 textClr3">سازمان ابلاغ دهنده</p>
                                <p className="faFont textSize9 textClr3">شماره نامه</p>
                                <p className="faFont textSize9 textClr3">تاریخ ابلاغیه</p>
                            </div>
                            <div className="flex flexCol notifTableList">
                                {
                                    mainData.data.map((item,index)=>{
                                        return <div className={`grid notificationListItem`} key={index}>
                                            <p className="faFont textSize9 textClr3 notifItemName grid">
                                                <span className="notifTitleSpan">
                                                    عنوان :
                                                </span>
                                                {item.title}
                                            </p>
                                            <p className="faFont textSize9 textClr3 notifItemOrgnize grid">
                                                <span className="notifTitleSpan">
                                                    سازمان ابلاغ دهنده :
                                                </span>
                                                {item.issuerOrganization}
                                            </p>
                                            <p className="faFont textSize9 textClr3 notifItemLetterNum grid">
                                                <span className="notifTitleSpan">
                                                    شماره نامه :
                                                </span>
                                                {item.letternum}
                                            </p>
                                            <div className="flex notifItemFooter">
                                                {
                                                    item.dateOfIssue && <p className="faFont textSize9 notifItemDate grid">
                                                        <span className="notifTitleSpan">
                                                            تاریخ ابلاغیه :
                                                        </span>
                                                    {
                                                        item.dateOfIssue
                                                    }
                                                    </p>
                                                }
                                                {
                                                    item.fileAddress && <a href={item.fileAddress} className="flex alignCenter gap5 dlLink faFont textClr3 textSize9">
                                                        <DownloadIcon/>
                                                        دانلود
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            <PagePagintaion data={{totalNumber:mainData.totalNumber,
                                changePage : changePage,
                                currentPage:currentPage}} />
                            
                        </div> : <div className="flex flexCol loadingNotifList">
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                        </div>
                    }
                    {
                        mainData.data && mainData.loading ? <div className="flex flexCol fetchingDataDiv flexCenter">
                            <div className="flex flexCenter flexCol loadingParentDiv">
                            <div className="flex fetchingLoadingItem"></div>
                            </div>
                        </div> : ""
                    }
                </div>
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
    </>
}