import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../App";
import AddsList from "./AdsList";
import "../style/famousDetails.css";
import DOMPurify from "dompurify";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import 'swiper/css';
import {ReactComponent as CloseIcon} from "../icon/close.svg";
import {ReactComponent as ArrowRight } from "../icon/arrowRight.svg";
import {ReactComponent as ArrowLeft } from "../icon/arrowLeft.svg";
export default function FamousDetails(){
    const params = useParams();
    const navigate = useNavigate();
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const [showSlider,setShowSlider] = useState(false);
    const [swiper,setSwiper] = useState(<Swiper/>);
    const [activeItem,setActiveItem] = useState({
        src:null,
        cover:null,
    })
    const [mainData,setMainData] = useState({
        ads:null,
        fullName : null,
        data: null,
        imgs:null,
        persianName : null,
    });
    useEffect(() => {
        setCity(params.name ? params.name : "melli");
        fetchData();
    }, [,navigate]);
    function fetchData(){
        const personId = params.id;
        if(!personId){
            navigate("/"+city);
            return;
        }
        const data = new FormData();
        data.append("pid",personId );
        data.append("name",params.name );
        fetch("https://web.beladkhabar.ir/Enrollment/GetPeopleById",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            const temp = json[0];
            document.title = "زندگی نامه " +temp.fullName;
            setMainData({
                data : temp.notif[0].detail[0],
                ads: temp.ads,
                fullName : temp.fullName,
                imgs:temp.notif[0].images,
                videos:temp.notif[0].videos,
                persianName : temp.persianName
            })
            if(temp.notif[0].videos){
                setActiveItem({
                    src : temp.notif[0].videos[0].src,
                    cover : temp.notif[0].videos[0].cover,
                });
            }
            
        })
        .catch((err) => {
        });
    }
    function MyComponent({ htmlContent }) {
        const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
          ALLOWED_TAGS: ['b', 'i', 'p', 'a','span','br'],
          ALLOWED_ATTR: ['href'],
        });
        return (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
    }
    function showSwiper(index){
        swiper.slideTo(index,0);
        setShowSlider(true);
    }
    return<>
        {
             mainData.data && <div className="flex alignCenter directionDiv container mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                <Link className="faFont textSize9 textClr3 directionLink rel" to={city}>{mainData.persianName}</Link>
                <p className="faFont textSize9 textClr3">
                    {"زندگی نامه " +  mainData.fullName}
                </p>
            </div>
        }
        <section className={'flex flexCol famousMainDiv container mar20'}>
            <div className="flex flexCol rel row1RightDiv">
                {
                    mainData.data ? <> <div className="flex flexCol gap20">
                    <div className="flex flexCol famousPersonDetailsDiv">
                        <img src={mainData.data.src} alt={mainData.fullName} draggable={false} width="100%" className="famousPersonImage"/>
                        <h1 className="faFont textClr3 famousPersonName">
                            {
                                mainData.fullName
                            }
                        </h1>
                        <div className="flex flexCol famousDetailsShort faFont">
                        {
                            MyComponent({htmlContent:mainData.data.dAbstract})    
                        }
                        </div>
                        <div className="flex flexCol famousDetailsContent faFont">
                        {
                            MyComponent({htmlContent:mainData.data.contents})    
                        }
                        </div>
                    </div>
                    
                    {
                        mainData.imgs && <div className="flex flex flexCol imgSection">
                            <h2 className="faFont textSize1 textClr3">
                                گالری تصاویر
                            </h2>
                            <div className="flex imgListDiv">
                                {
                                    mainData.imgs.map((item,index)=>{
                                        return <img src={item.src} alt={mainData.fullName} className="imgItem" key={index} onClick={()=>showSwiper(index)}/>
                                    })
                                }
                            </div>
                        </div>
                    }
                    {
                        mainData.videos && <div className="flex flexCol videoListDiv">
                            <div className={mainData.videos.length > 1 ? "grid famousVideoGrid" : "flex flexCol"}>
                                <video controls className="mainVideo" poster={activeItem.cover} src={activeItem.src}>
                                    Your browser does not support the video tag.
                                </video>
                                <div className="grid videoListBtns customScrollbar">
                                    {
                                        mainData.videos.map((item,index)=>{
                                            return <div className={activeItem.src === item.src ? "flex flexCol videoItem selected" : "flex flexCol videoItem"} key={index} onClick={()=>setActiveItem({src:item.src,cover:item.cover})}>
                                                <img src={item.cover} alt={mainData.fullName + " ویدیو"} className="videoListImg" />
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    </div>
                    <div className={showSlider ? "famouseSwiperCloserDiv" : "famouseSwiperCloserDivHide"} onClick={()=>setShowSlider(false)}></div>
                    {
                    mainData.imgs && mainData.imgs.length > 0 ? <div className={showSlider ? "flex flexCol flexCenter imgSwiperContainer" : "imgSwiperContainer hideSlider"} >
                        <div className="flex flexCol imgSwiperParentDiv rel">
                            <button className="flex flexCenter closeSliderBtn" onClick={()=>setShowSlider(false)}>
                                <CloseIcon />
                            </button>
                            <Swiper className="swiper imgListSwiper"
                                modules={[Navigation]}
                                spaceBetween={20}
                                slidesPerView={1}
                                onSwiper={(swiper) => setSwiper(swiper)}
                                >
                                {
                                    mainData.imgs.map((item,index)=>{
                                        return <SwiperSlide key={index}>
                                            <img src={item.src} alt={mainData.fullName} className="famousSwiperImg" draggable="false" />
                                        </SwiperSlide>
                                    })
                                }
                            </Swiper>
                            <div className="flex sliderNavDiv famousNavsDiv">
                                <button className="prevSlide flex flexCenter" onClick={()=>swiper.slidePrev()}>
                                    <ArrowRight />
                                    <span className="hideText">prev</span>
                                </button>
                                <button className="nextSlide flex flexCenter" onClick={()=>swiper.slideNext()}>
                                    <ArrowLeft />
                                    <span className="hideText">next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    : ""
                    }
                    </>
                    : 
                    
                    <div className="flex flexCol loadMainContent gap10">
                        <div className="loadingFamousTitle rel"></div>
                        <div className="loadingFamousImage rel"></div>
                        <div className="loadingSimpleText rel"></div>
                        <div className="loadingSimpleText rel"></div>
                        <div className="loadingSimpleText rel"></div>
                        <div className="loadingSimpleText rel"></div>
                    </div>
                }
            </div>
            <div className="flex flexCol row1LeftDiv">
                <AddsList data={{"ads":mainData.ads}}/>
            </div>
        </section>
    </>
}