import DOMPurify from "dompurify";

function shortText(text, len) {
  return text.length > len ? text.slice(0, len) + " ..." : text;
}
let startX;
let scrollLeft;
let isDown;
function mouseIsDown(e, className) {
  const offersList = document.querySelector("." + className);
  isDown = true;
  startX = e.pageX - offersList.offsetLeft;
  scrollLeft = offersList.scrollLeft;
  offersList.style.cursor = "pointer";
}
function mouseUp(e, className) {
  const offersList = document.querySelector("." + className);
  isDown = false;
  offersList.style.cursor = "default";
}
function mouseLeave(e, className) {
  const offersList = document.querySelector("." + className);
  isDown = false;
  offersList.style.cursor = "default";
}
function mouseMove(e, className) {
  const offersList = document.querySelector("." + className);
  if (isDown) {
    e.preventDefault();
    const x = e.pageX - offersList.offsetLeft;
    offersList.scrollLeft = scrollLeft - (x - startX);
  }
}
function scrollToTop() {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}
async function share(text) {
  const shareData = {
    title: "این مطلب را با دیگران به اشتراک بگذارید.",
    text: text,
    url: window.location.href,
  };
  try {
    await navigator.share(shareData);
  } catch (err) {}
}
function MyComponent({ htmlContent }) {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
    ALLOWED_TAGS: ["b", "i", "p", "a", "span", "br"],
    ALLOWED_ATTR: ["href"],
  });
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
}
function timeInShamsi(year, mount, day) {
  year = +year;
  mount = +mount;
  day = +day;
  var g_d_m, jy, jm, jd, gy2, days;
  g_d_m = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
  gy2 = mount > 2 ? year + 1 : year;
  days =
    355666 +
    365 * year +
    ~~((gy2 + 3) / 4) -
    ~~((gy2 + 99) / 100) +
    ~~((gy2 + 399) / 400) +
    day +
    g_d_m[mount - 1];
  jy = -1595 + 33 * ~~(days / 12053);
  days %= 12053;
  jy += 4 * ~~(days / 1461);
  days %= 1461;
  if (days > 365) {
    jy += ~~((days - 1) / 365);
    days = (days - 1) % 365;
  }
  if (days < 186) {
    jm = 1 + ~~(days / 31);
    jd = 1 + (days % 31);
  } else {
    jm = 7 + ~~((days - 186) / 30);
    jd = 1 + ((days - 186) % 30);
  }
  if (jm < 10) {
    jm = `0${jm}`;
  }
  if (jd < 10) {
    jd = `0${jd}`;
  }
  return [
    numbersToPer(String(jy)) +
      "/" +
      numbersToPer(String(jm)) +
      "/" +
      numbersToPer(String(jd)),
  ];
}

let persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"],
  englishNumbers = [/0/g, /1/g, /2/g, /3/g, /4/g, /5/g, /6/g, /7/g, /8/g, /9/g];
function numbersToPer(str) {
  if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
      str = str.replace(englishNumbers[i], persianNumbers[i]);
    }
  }
  return str;
}
export {
  shortText,
  mouseIsDown,
  mouseMove,
  mouseUp,
  mouseLeave,
  scrollToTop,
  share,
  MyComponent,
  timeInShamsi,
};
