import React, { useContext, useEffect } from "react";
import "../style/notFound.css";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
export default function NotFound(){
    const {currentCity} = useContext(AppContext);
    const [, setCity] = currentCity;
    useEffect(() => {
        document.title = "صفحه مورد نظر یافت نشد.";
        setCity("melli");
    }, []);
    return <section className="notFoundSection container grid">
        <div className="flex flexCol notFoundRightDiv flexCenter">
            <p className="faFont notFoundNumber flex flexCenter">404</p>
            <h1 className="faFont textSize16 textClr3 flex flexCenter">
                صفحه مورد نظر یافت نشد.
            </h1>
            <p className="faFont textSize9 textClr7">
                صفحه ای که دنبال آن بودید یافت نشد.
                <br/>
                ممکن است به آدرس دیگری منتقل شده یا از سایت حذف شده باشد.
            </p>
            <Link to="/" className="faFont textSize1 textClrF notFoundLink">رفتن به صفحه اصلی</Link>
        </div>
        <img src="/image/notFound.webp" alt="" className="notFoundImage" />
    </section>
}