import React, { useEffect, useState } from "react";
import {ReactComponent as Map} from "../icon/map.svg";
import "../style/map.css";
import { Link, useNavigate } from "react-router-dom";
const perName = {
    "alborz":{
        name:"البرز",
        left:33,
        top:18
    },
    "ardabil":{
        name:"اردبیل",
        left:19,
        top:0
    },
    "azerbaijan-east":{
        name:"آذربایجان‌شرقی",
        left:11,
        top:0
    },
    "azerbaijan-west":{
        name:"آذربایجان‌غربی",
        left:4,
        top:0
    },
    "bushehr":{
        name:"بوشهر",
        left:32,
        top:57
    },
    "chahar-mahaal-bakhtiari":{
        name:"چهارمحال‌بختیاری",
        left:30,
        top:41
    },
    "fars":{
        name:"فارس",
        left:42,
        top:50
    },
    "gilan":{
        name:"گیلان",
        left:26,
        top:10
    },
    "golestan":{
        name:"گلستان",
        left:54,
        top:10
    },
    "hamadan":{
        name:"همدان",
        left:21,
        top:22
    },
    "hormozgan":{
        name:"هرمزگان",
        left:60,
        top:65
    },
    "ilam":{
        name:"ایلام",
        left:10,
        top:33
    },
    "isfahan":{
        name:"اصفهان",
        left:38,
        top:35
    },
    "kerman":{
        name:"کرمان",
        left:62,
        top:50
    },
    "kermanshah":{
        name:"کرمانشاه",
        left:10,
        top:27
    },
    "khorasan-north":{
        name:"خراسان‌شمالی",
        left:64,
        top:9
    },
    "khorasan-razavi":{
        name:"خراسان‌رضوی",
        left:71,
        top:14
    },
    "khorasan-south":{
        name:"خراسان‌جنوبی",
        left:74,
        top:33
    },
    "khuzestan":{
        name:"خوزستان",
        left:21,
        top:40
    },
    "kohgiluyeh-boyer-ahmad":{
        name:"کهگیلویه‌و‌بویر‌احمد",
        left:30,
        top:49
    },
    "kurdistan":{
        name:"کردستان",
        left:12.5,
        top:18
    },
    "lorestan":{
        name:"لرستان",
        left:20,
        top:32.5
    },
    "markazi":{
        name:"مرکزی",
        left:27,
        top:25
    },
    "mazandaran":{
        name:"مازندران",
        left:39,
        top:17
    },
    "qazvin":{
        name:"قزوین",
        left:27.5,
        top:17.5
    },
    "qom":{
        name:"قم",
        left:33,
        top:25.5
    },
    "semnan":{
        name:"سمنان",
        left:51,
        top:19
    },
    "sistan-baluchestan":{
        name:"سیستان‌و‌بلوچستان",
        left:82,
        top:60
    },
    "tehran":{
        name:"تهران",
        left:38,
        top:21
    },
    "yazd":{
        name:"یزد",
        left:60,
        top:35
    },
    "zanjan":{
        name:"زنجان",
        left:20,
        top:14
    }
};
export default function MapView(props){
    const navigate = useNavigate();
    const data = props.data;
    let suggestVilageTimer = null;
    const [searchVillageList , setSearchVillageList] = useState([]);
    useEffect(() => {
        const cityName = document.querySelector(".cityName");
        const allCity = document.querySelectorAll(".city");
        allCity.forEach(item=>{
            item.addEventListener("click",()=>{
                navigate("/"+item.dataset.name);
            })
            item.addEventListener("mouseover",()=>{
                cityName.innerText = perName[item.dataset.name].name;
                cityName.style.left = perName[item.dataset.name].left+"%";
                cityName.style.top = perName[item.dataset.name].top+"%";
            })
        })
    }, []);
    function checkInput(val){
        if(!data.villages || data.villages.length === 0){
            return;
        }
        if(val === ""){
            setSearchVillageList([]);
            return;
        }
        if(suggestVilageTimer){
            clearTimeout(suggestVilageTimer)
        }
        suggestVilageTimer = setTimeout(() => {
            let tmpArray = [];
            data.villages.forEach(item=>{
                if(item.title.indexOf(val) > -1){
                    tmpArray.push(item);
                }
            })
            setSearchVillageList(tmpArray);
        }, 1000);
    }
    return <section className="flex flexCol mapSection">
        <div className="flex flexCol container mapContainer">
            <div className="mapGrid grid">
                <div className="flex flexCol mapDiv rel">
                    <Map className="mapSvg"/>
                    <p className="faFont textSize11 textClrF cityName"></p>
                </div>
                {
                    data && data.villages && data.villages.length > 0 ? <div className="flex flexCol gap10">
                        <h2 className="faFont textSize14 textClrF flex flexCenter">
                            روستاهایی که اخیرا به بلادخبر پیوسته اند.
                        </h2>
                        <div className="flex flexCol vilageList">
                        {
                            data.villages.map((item,index)=>{
                                if(index < 10)
                                {
                                    return <Link to={"/"+item.latinName} className="faFont textSize11 textClrF vilageItem flex alignCenter gap5" key={index}>
                                    {
                                        item.title
                                    }
                                    <span className="textSize8">
                                    {
                                        "(" + item.provincePersianName + ")"
                                    }
                                </span>
                                    </Link>
                                }
                            })
                        }
                        </div>
                    </div> : ""
                }
            </div>
            <div className="searchVilageBox flex flexCol rel">
                <input type="text" className="faFont textSize9 textClr3 vilageSearchInput" name="name" placeholder="نام روستا را اینجا وارد کنید ..." onKeyUp={(e)=>checkInput(e.target.value)} autoComplete="off" />
                <div className="flex villageSearchResult flexCol customScrollbar">
                    {
                        searchVillageList && searchVillageList.map((item,index)=>{
                            return <Link key={index} to={"/"+item.latinName} className="searchLinkItem flex flexCol gap5" target="_blank">
                                <p className="faFont textSize9 textClr3 flex alignCenter gap5">
                                    {
                                        item.title
                                    }
                                    <span className="textSize8">
                                        {
                                            "(" + item.provincePersianName + ")"
                                        }
                                    </span>
                                </p>
                                <p className="faFont textSize8 textClr7">
                                    {
                                        item.Description
                                    }
                                </p>
                            </Link>
                        })
                    }
                </div>
            </div>
        </div>
    </section>
}