import React from "react";
export default function Time(props) {
    const data = props.data;
    
    return <>
        {
            data && data.time ? 
            <iframe src={data.time} frameBorder="0" className="timeFrame"></iframe>
            : ""
        }
    </>
}
