import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams ,Link} from "react-router-dom";
import { AppContext } from "../App";
import AddsList from "./AdsList";
import "../style/famousDetails.css";
import DOMPurify from "dompurify";
import { scrollToTop } from "./Methods";
import PagePagintaion from "./PagePagination";
export default function CityDetails(props){
    const pageName = props.data.pageName;
    const params = useParams();
    const navigate = useNavigate();
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const [ mainData,setMainData ] = useState({
        ads : null,
        list : null,
        totalNumber : 0,
        persianName : null,
        loadingPagination : false,
        caption:null,
    });
    const currentPage = useRef(1);
    useEffect(() => {
        setCity(params.name ? params.name : "melli");
        fetchData();
    }, [,navigate]);
    function fetchData(){
        scrollToTop();
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("notificationName", pageName);
        data.append("name", cityName);
        data.append("password", "1234567890");
        data.append("pageIndex",currentPage.current);
        data.append("pageCount",12);
        fetch("https://web.beladkhabar.ir/Enrollment/GetNotificationsByName",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            const tempCity = json[0].persianName
            pageName === "famous" && (document.title = "اسامی افراد شاخص " + tempCity );
            pageName === "obituary" && (document.title = "زندگی نامه درگذشتگان  " + tempCity );
            pageName === "excellentStudents" && (document.title = "دانش آموزان برتر  " + tempCity );
            pageName === "Introducingjobsandbusinesses" && (document.title = "معرفی مشاغل و کسب و کار " + tempCity );
            pageName === "Requirements" && (document.title = "نیازمندی های " + tempCity );
            setMainData({
                ads : json[0].ads,
                list : json[0].notif[0].notifications,
                persianName : json[0].persianName,
                totalNumber : Math.ceil(json[0].notif[0].Cnt / 12),
                loadingPagination : false,
                caption : json[0].caption === "ندارد" ? null : json[0].caption
            })
            
        })
        .catch((err) => {
        });
    }
    console.log(mainData);
    function MyComponent({ htmlContent }) {
        const sanitizedHtml = DOMPurify.sanitize(htmlContent, {
          ALLOWED_TAGS: ['b', 'i', 'p', 'a','span','br'],
          ALLOWED_ATTR: ['href'],
        });
        return (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        );
    }
    function changePage(nextPage){
        currentPage.current = nextPage;
        setMainData({...mainData,loadingPagination:true});
        fetchData();
    }
    return <>
        <section className={'grid row1Grid gridRow container mar20'}>
            <div className="flex flexCol rel">
                {
                    mainData.list && mainData.list ? 
                    <>
                        <div className="flex alignCenter directionDiv">
                            <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                            <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>
                            <p className="faFont textSize9 textClr3">
                                {
                                    pageName === "famous" &&  "اسامی افراد شاخص " + mainData.persianName 
                                }
                                {
                                    pageName === "obituary" &&  "زندگی نامه درگذشتگان " + mainData.persianName 
                                }
                                {
                                    pageName === "excellentStudents" &&
                                            "دانش آموزان برتر " + mainData.persianName
                                }
                                {
                                    pageName === "Introducingjobsandbusinesses" && "معرفی مشاغل و کسب و کار " + mainData.persianName 
                                }
                                {
                                    pageName === "Requirements" && "نیازمندی های " + mainData.persianName 
                                }
                            </p>
                        </div>
                        <div className="flex alignCenter justifyBetween studentHeaderDiv">

                        {
                            pageName === "famous" && <h1 className="faFont textSize14 textClr3 famousTitle">
                                { "اسامی افراد شاخص " + mainData.persianName }
                            </h1>
                        }
                        {
                            pageName === "obituary" && <h1 className="faFont textSize14 textClr3 famousTitle">
                                { "زندگی نامه درگذشتگان " + mainData.persianName }
                            </h1>
                        }
                        {
                        pageName === "excellentStudents" && 
                            <h1 className="faFont textSize14 textClr3 famousTitle">
                                { "دانش آموزان برتر " + mainData.persianName }
                            </h1>
                            
                        }
                        {
                            mainData.caption && <p className="faFont textSize14 textClr3 studentYear">
                            {
                                mainData.caption
                            }
                            </p>
                        }
                        </div>
                        {
                            pageName === "Introducingjobsandbusinesses" && <h1 className="faFont textSize14 textClr3 famousTitle">
                                { "معرفی مشاغل و کسب و کار " + mainData.persianName }
                            </h1>
                        }
                        {
                            pageName === "Requirements" && <h1 className="faFont textSize14 textClr3 famousTitle">
                                { "نیازمندی های " + mainData.persianName }
                            </h1>
                        }
                        <div className="grid famousGrid">
                            {
                                mainData.list.map((item,index)=>{
                                    return <div className="flex famousItem flexCol" key={index}>
                                        {
                                            item.link !== "#" ? <Link className="famousImageLink" to={item.link} target={item.target} draggable="false">
                                                <img src={item.src} draggable="false"  alt={"عکس "+item.title} className="famousImage" />
                                            </Link> :  
                                            <div className="famousImageLink" >
                                                    <img src={item.src} draggable="false"  alt={"عکس "+item.title} className="famousImage" />
                                                </div>
                                            }
                                            <div className="flex flexCol famousInfoDiv">
                                                {
                                                    item.link !== "#" ? 
                                                    <Link className="faFont textSize11 textClr3 famousName" target={item.target} to={item.link} draggable="false">
                                                        {
                                                            item.title
                                                        }
                                                    </Link>
                                                     : 
                                                     <p className="faFont textSize11 textClr3 famousName" >
                                                        {
                                                            item.title
                                                        }
                                                    </p>
                                                }
                                                {
                                                    item.title1 && <p className="faFont textSize9 textClr3 itemTitle1" >
                                                    {
                                                        item.title1
                                                    }
                                                </p>
                                                }
                                                {
                                                    item.notificationAbstract && <div className="faFont textSize9 textClr5 descItems">
                                                    {
                                                        MyComponent({htmlContent:item.notificationAbstract})
                                                    }
                                                    </div>   
                                                }
                                            </div>
                                    </div>
                                })
                            }
                        </div>
                        <PagePagintaion data={{totalNumber:mainData.totalNumber,
                            changePage : changePage,
                            currentPage:currentPage}} />
                        
                    </>
                    : <div className="flex flexCol gap10">
                        <div className="loadingFamousTitle rel"></div>
                        <div className="grid loadingFamousGrid">
                            <div className="loadingFamousItem rel"></div>
                            <div className="loadingFamousItem rel"></div>
                            <div className="loadingFamousItem rel"></div>
                            <div className="loadingFamousItem rel"></div>
                            <div className="loadingFamousItem rel"></div>
                            <div className="loadingFamousItem rel"></div>
                        </div>
                    </div>
                }
                {
                    mainData.loadingPagination && <div className="loadingPagintaionDiv flex flexCenter">
                        <div className="loadingPagintaionBg">
                            <div className="loadingPagintaionSpin"></div>
                        </div>
                    </div>
                }
            </div>
            <div className="flex flexCol row1LeftDiv mar20">
                <AddsList data={{"ads":mainData.ads}}/>
            </div>
        </section>
    </>
}