import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import '../style/videoShow.css';
import {ReactComponent as PlayLogo} from "../icon/play.svg";
import {ReactComponent as LeftLogo} from "../icon/arrowLeft.svg";
import {ReactComponent as TimeLogo} from "../icon/time.svg";
import {ReactComponent as VisitLogo} from "../icon/Visit.svg";
export default function VideoShow(props){
    const param = useParams();
    const navigate = useNavigate();
    const city = param.name ? param.name : "";
    const data = props.data;
    const [activeItem,setActiveItem]= useState({
        poster:"",
        url:"",
        title:"",
        firstTime : true,
    });
    if(activeItem.firstTime && data.videos && data.videos.length > 0){
        setActiveItem(data.videos[0]);
    }
    useEffect(() => {
        setActiveItem({
            poster:"",
            url:"",
            title:"",
            firstTime : true,
        });
    }, [navigate]);
    return <div className="flex flexCol videoMainDiv">
        {
            data.showTitle && <div className="flex alignCenter justifyBetween">
                <h2 className="faFont textSize16 textClrF">
                    ویدیوها
                </h2>
                <Link to={city !== "" ? "/" + city + "/videos" : "/videos"} className="faFont textSize1 textClrF seeAllVideo flex alignCenter gap5">
                مشاهده همه
                <LeftLogo />
                </Link>
            </div>
        }
        {
            data && data.videos && data.videos.length > 0? 
            <div className="grid videoGrid">
                {
                    <div className="flex flexCol largeVideoDiv rel">
                        <img src={activeItem.poster} alt={activeItem.title} className="largeVideoDivImage" />
                        <p className="faFont textSize12 textClrF largeVideoTitle">
                            {
                                activeItem.title
                            }
                        </p>
                        {
                            activeItem.duaration && <p className="faFont textSize9 textClrF durationText flex alignCenter gap5">
                                <TimeLogo />
                                <span>
                                {
                                    activeItem.duaration
                                }
                                </span>
                            </p>
                        }
                        {
                             activeItem.visitedCount && <p className="faFont textSize9 textClrF visitText flex alignCenter gap5">
                                <VisitLogo />
                                <span>
                                {
                                    activeItem.visitedCount
                                }
                                </span>
                            </p>
                        }
                        
                        <button className="videoLargePlayBtn flex flexCenter">
                            <PlayLogo/>
                            <span className="hideText">play</span>
                        </button>
                        <a href={activeItem.url} className="largeVideoLink">
                            {activeItem.title}
                        </a>
                    </div> 
                }
                
                <div className="flex flexCol smallVideoList customScrollbar">
                    {
                        data.videos && data.videos.map((item,index)=>{
                            return <div className={item.url === activeItem.url ? "grid smallVideoItem active" : "grid smallVideoItem"} key={index} onClick={()=>setActiveItem(item)}>
                                <img src={item.poster} alt={item.title} className="smallVideoImage" />
                                <div className="flex flexCol">
                                    <p className="faFont textSize11 textClrF">
                                        {item.title}
                                    </p>
                                   <div className="flex alignCenter justifyBetween">
                                        <p className="faFont textSize9 textClrF flex alignCenter gap5">
                                            <VisitLogo />
                                            <span>
                                            {
                                                item.visitedCount
                                            }
                                            </span>
                                        </p>
                                        <p className="faFont textSize9 textClrF flex alignCenter gap5">
                                            <TimeLogo />
                                            <span>
                                            {
                                                item.duaration
                                            }
                                            </span>
                                        </p>
                                   </div>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            :
            <div className="grid videoLoadingDiv">
                 <div className="mainLoadingVideoDiv loadingItemDiv rel"></div>
                 <div className="grid listVideoLoadingDivs">
                    <div className="listVideoLoadingItem loadingItemDiv rel"></div>
                    <div className="listVideoLoadingItem loadingItemDiv rel"></div>
                    <div className="listVideoLoadingItem loadingItemDiv rel"></div>
                 </div>
            </div>
        }
    </div>
}