import React from "react";
import '../style/middleAds.css';
export default function MiddleAdds(props){
    const data = props.data;
    let adsList = "";
    if(data.ads){
        adsList = [];
        data.ads.map((item)=>{
            if(item.sectionId === 2){
                adsList.push(item)
            }
        })
    }
    return <>
    {
        !data ? <div className="flex flexCol loadMiddleAdds"></div> :
        data && adsList && adsList.length > 0 ?
        <div className="grid adsGrid">
            {
                data.ads.map((item,index)=>{
                    return <a href={item.link} className="largeAddLink mar20" key={index}>
                        <img src={item.src} alt="ads" className="largeAddImage" />
                    </a>
                })
            }
        </div>
        :""
    }
    </>
}