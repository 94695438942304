import React from "react";
import SmallAds from "./SmallAds";
import '../style/adsList.css';
export default function AddsList(props){
    const data = props.data;
    let adsList = "";
    if(data.ads){
        adsList = [];
        data.ads.map((item)=>{
            if(item.sectionId === 1){
                adsList.push(item)
            }
        })
    }
    return <>
        {
            data.ads === null ?  <div className="flex flexCol adsLoading">
                <div className="flex flexCol adsLoadingItem rel"></div>
                <div className="flex flexCol adsLoadingItem rel"></div>
                <div className="flex flexCol adsLoadingItem rel"></div>
                <div className="flex flexCol adsLoadingItem rel"></div>
            </div> :
            data && adsList && adsList.length > 0 ?
            <div className="flex flexCol gap10">
                <div className="flex flexCol adsTitleDiv">
                    <h3 className="faFont textSize1 adsTitle">
                        تبلیغات
                    </h3>
                </div>
                <div className="grid adsGrid">
                {
                    data.ads.map((item,index)=>{
                        return <SmallAds data={item} key={index}/>
                    })
                }
            </div>
            </div>
            : ""
        }
    </>
}