import React from "react";
import { useSwiper } from "swiper/react";
import {ReactComponent as ArrowRight } from "../icon/arrowRight.svg";
import {ReactComponent as ArrowLeft } from "../icon/arrowLeft.svg";
export default function SwiperNavBtns() {
    const swiper = useSwiper();
    return <>
        <button className="prevSlide flex flexCenter" onClick={()=>swiper.slidePrev()}>
            <ArrowRight />
            <span className="hideText">prev</span>
        </button>
        <button className="nextSlide flex flexCenter" onClick={()=>swiper.slideNext()}>
            <ArrowLeft />
            <span className="hideText">next</span>
        </button>
    </>
}