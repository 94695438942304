import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper/modules";
import 'swiper/css';
import '../style/fullSlider.css';
import { Link } from "react-router-dom";
export default function FullSlider(props){
    const data = props.data;
    return <>
    {
            data && data.sliders ? <section className="flex sliderSection container">
                {
                    data.sliders && data.sliders.length > 0 &&
                    <Swiper className={data.banners.length > 0 ? "swiper sliderSwiper rel" : "swiper fullSliderSwiper rel"} 
                        modules={[Autoplay,Navigation]}
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        autoplay={{
                            delay: 5000,
                        }}
                        >
                        {
                            data.sliders.map((item,index)=>{
                                return index < 5 ? <SwiperSlide key={index}>
                                <div className="flex flexCol silderItem rel" >
                                    <Link to={item.link} className="sliderImageLink flex flexCol">
                                        <img src={item.src} alt={item.link} className="sliderImage" />
                                    </Link>
                                    <Link to={item.link} className="faFont textSize11 textClr3 sliderLink">{item.title}</Link>
                                </div>
                            </SwiperSlide> : ""
                            })
                        }
                    </Swiper>
                }
                {
                    data.banners && data.banners.length > 0 &&
                    <div className="flex flexCol nofitList">
                        {
                            data.banners.map((item,index)=>{
                                return <Link className="flex flexCol notifItem rel"  to={item.link} key={index}>
                                    <img src={item.src} alt={item.id} className="notifImage" />
                                </Link>
                            })
                        }
                    </div>
                }
            </section>
            :
            <div className="flex sliderLoadingDiv container">
                <div className="flex flexCol largeSliderLoadingDiv rel"></div>
                <div className="flex flexCol notifLoadingList">
                    <div className="flex flexCol notifLoadingDiv rel"></div>
                    <div className="flex flexCol notifLoadingDiv rel"></div>
                    <div className="flex flexCol notifLoadingDiv rel"></div>
                    <div className="flex flexCol notifLoadingDiv rel"></div>
                </div>
            </div>
        }
    </>
}