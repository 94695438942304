import React, { useContext, useEffect, useRef, useState } from "react";
import AddsList from "./AdsList";
import { AppContext } from "../App";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style/tableList.css";
import PagePagintaion from "./PagePagination";
import { scrollToTop } from "./Methods";
import NewsSwipeList from "./NewsSwipeList";
export default function SubNewsList(props){
    const {pageName,pageTitle} = props.data;
    const navigate = useNavigate();
    const params = useParams();
    const {cname} = params;
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentPage = useRef(1);

    const [mainData,setMainData] = useState({
        ads: null,
        data : null,
        persianName : null,
        totalNumber : 0,
        loading:false,
        relative : null,
    });
    
    useEffect(() => {
        document.title = pageTitle;
        setCity(cname ? cname : "melli");
        fetchData();
    }, []);
    function fetchData(){
        scrollToTop();
        currentPage.current = queryParams.get("p") ? queryParams.get("p") : 1;
        const cityName = cname ? cname : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("cid",1);
        data.append("pageCount",10);
        data.append("pageIndex",currentPage.current);
        data.append("categoryName",pageName);
        fetch("https://web.beladkhabar.ir/Enrollment/Getpagelist",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(json.msg);
            }
            const tempData = json[0];
            setMainData({
                ads:tempData.ads,
                data:tempData.dt[0].pages,
                persianName:tempData.persianName,
                totalNumber:tempData.dt[0].recordCounts,
                loading:false,
                relative : tempData.relatedNews,
            });
        })
        .catch((err) => {
            setMainData({...mainData,loading : false})
        });
    }
    function changePage(newPage){
        setMainData({...mainData,loading : true});
        queryParams.set("p",newPage);
        window.history.pushState({},"",`${window.location.pathname}?${queryParams}`);
        fetchData();
    }
    return <>
        <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>)
                }
                {
                    pageTitle && <p className="faFont textSize9 textClr3">
                    {
                        pageTitle
                    }
                    </p>
                }
            </div>
            <div className="grid tableListGrid">
                <div className="flex flexCol">
                    {
                        mainData.data ? <div className="flex flexCol tableListContainer">
                            <h1 className="faFont textSize12 textClr3 tableTitle">
                                {
                                    pageTitle
                                }
                            </h1>
                            <div className={`flex flexCol tableTitleDiv`}>
                                <p className="faFont textSize1 textClr3">عنوان</p>
                            </div>
                            <div className="flex flexCol tableList">
                                {
                                    mainData.data.map((item,index)=>{
                                        return <div className={`grid tableListItem`} key={index}>
                                            {
                                                item.title && <Link draggable="false" to={item.link} className="faFont textSize1 textClr3 grid">
                                                    <span className="titleSpan">
                                                        عنوان :
                                                    </span>
                                                    {item.title}
                                                </Link>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                            <PagePagintaion data={{totalNumber:mainData.totalNumber,
                                changePage : changePage,
                                currentPage:currentPage}} />
                            
                        </div> : <div className="flex flexCol loadingNotifList">
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                            <div className="flex flexCol notifLoadingItem rel"></div>
                        </div>
                    }
                    {
                        mainData.data && mainData.loading ? <div className="flex flexCol fetchingDataDiv flexCenter">
                            <div className="flex flexCenter flexCol loadingParentDiv">
                            <div className="flex fetchingLoadingItem"></div>
                            </div>
                        </div> : ""
                    }
                </div>
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
        {
            mainData.relative && <NewsSwipeList data={{news:mainData.relative}} info={{title:"اخبار مرتبط",hasLocation:false,path:"/"}}/>
        }
    </>
}