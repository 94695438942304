import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper/modules";
import 'swiper/css';
import "../style/newsSwipeList.css";
import Loading from "./Loading";
import SwiperNavBtns from "./SwiperNavBtns";
import { Link } from "react-router-dom";
import {ReactComponent as LocationLogo } from "../icon/location.svg";
import { shortText } from "./Methods";
export default function NewsSwipeList(props){
    const info = props.info;
    const data = props.data;
    const path = props.info.path ? props.info.path : "";
    const newsList = useRef(null);
    if(data.news){
        newsList.current = data.news;
    }
    return <>
        <section className="flex flexCol newsSlideSection container rel">
            <div className="flex alignCenter justifyBetween">
                <h2 className="faFont textSize12 textClr3 newsSliderTitle">
                    {
                        info.title
                    }
                </h2>
            </div>
            <div className="flex flexCol newsSliderHolder">
                {
                    data && data.news ? <div className="flex flexCol">
                        <Swiper className="swiper newsSlider rel"
                            modules={[Autoplay,Navigation]}
                            spaceBetween={15}
                            slidesPerView={2}
                            loop={newsList.current.length > 6}
                            autoplay={{
                                delay: 5000,
                            }}
                            breakpoints={{
                                649: {
                                  slidesPerView: 3,
                                },
                                768:{
                                  slidesPerView: 4,
                                },
                                1150:{
                                  slidesPerView: 5,
                                }
                            }}
                            navigation={{
                                prevEl: '.prevSlide',
                                nextEl: '.nextSlide',
                            }}
                            
                            >
                            {
                                newsList.current.map((item,index)=>{
                                    return <SwiperSlide key={index}>
                                    <div className="flex flexCol newsSliderItem" >
                                        <Link to={path + item.link} target="_blank">
                                            <img src={item.src} alt={item.link} className="newsSliderImage" />
                                        </Link>
                                        <div className="flex flexCol newsSliderInfoDiv">
                                            <Link to={path + item.link} className="faFont textSize11 textClr3 newSliderTitle" target="_blank">{shortText(item.title , 60)}</Link>
                                            {
                                                info.hasLocation  ? <div className="flex alginCenter newsSliderInfoFooterDiv">
                                                    <p className={item.persianName !== "ملی" ? "faFont textSize9 textClr7 flex alignCenter gap5" : "faFont textSize9 textClr7 flex alignCenter gap5 op0"}>
                                                        <LocationLogo />
                                                        {
                                                            item.persianName
                                                        }
                                                    </p> 
                                                </div> : ""
                                            }
                                            
                                        </div>
                                    </div>
                                </SwiperSlide>
                                })
                            }
                            { newsList.current.length > 6 && <SwiperNavBtns/> }
                        </Swiper>
                    </div>
                    :
                    <div className="flex flexCol flexCenter newsSliderLoadingDiv">
                        <Loading />
                    </div>
                }
            </div>
        </section>
    </>
}