import React, { useContext, useEffect, useState } from "react"
import {ReactComponent as Arrow} from "../icon/doubleArrow.svg";
import {ReactComponent as Phone} from "../icon/phone.svg";
import {ReactComponent as Email} from "../icon/email.svg";
import {ReactComponent as ArrowTop} from "../icon/arrowTop.svg";
import { scrollToTop } from "./Methods";
import "../style/footer.css";
import { Link } from "react-router-dom";
import { AppContext } from '../App';
import 'font-awesome/css/font-awesome.min.css';
export default function Footer(){
    const {currentCity} = useContext(AppContext);
    const [city,] = currentCity;
    const [mainData,setMainData] = useState({
        aboutus : "",
        about : null,
        contact : null,
        fixedNav: null,
    });
    useEffect(()=>{
        setMainData(
            {
                aboutus : "",
                about : null,
                contact : null,
            }
        );
        fetchData();
     },[city]);
    function fetchData(){
        if(city === ""){
            return;
        }
        const data = new FormData();
        data.append("name", city);
        data.append("password", "1234567890");
        fetch("https://web.beladkhabar.ir/Enrollment/GetHeader",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
            throw new Error(json.msg);
            }
            setMainData(json[0]);
        })
        .catch(() => {
        });
    }
    return <>
    {
        mainData.mobileFooter ? <section className="flex flexCol fixedNavigation">
            <div className="grid fixedNavigationContianer">
                {
                    mainData.mobileFooter.map((item,index)=>{
                        return <Link to={item.url} key={index} className="flex flexCol faFont textClr3 alignCenter fixedLink">
                            <i title="l-1" className={item.src}></i>
                            لینک
                        </Link>
                    })
                }
            </div>
        </section> : ""
    }
     <footer className="flex flexCol">
     <section className="grid footerMainSecion container rel">
            <div className="flex flexCol gap10 row1">
                <Link to={"/"} className="footerLogoLink">
                    <img draggable="false" src="/image/logo.png" decoding="async" alt="belladKhabar" className="footerLogo" height="25"/>
                </Link>
                <h2 className="faFont textSize11 textClrC justifyText">
                    {
                        mainData.aboutus
                    }
                </h2>
                <div className="flex alignCenter socailLinks">
                    {
                        mainData.socialNetwork && mainData.socialNetwork.map((item,index)=>{
                            return <a href={item.url} key={index} className="textSize1 flex flexCenter headerSocialItem textClrF">
                            <i title="l-1" className={item.src}></i>
                             <span className="hideText">{item.title}</span>
                         </a>
                        })
                    }
                </div>
            </div>
            <div className="grid footerLeftSide">
                <div className="flex flexCol gap10 row2">
                    <h2 className="faFont textSize14 textClrD justifyText footerTitle rel">
                        دسترسی سریع
                    </h2>
                    <div className="flex flexCol gap5 fitContent mar10">
                        {
                            <Link to={mainData.about ? mainData.about : "" } draggable="false" className="faFont textSize11 textClrC flex alignCenter gap10 footerLink">
                                <Arrow />
                                درباره ما
                            </Link>
                        }
                        {
                            <Link to={mainData.contact ? mainData.contact : "" } draggable="false" className="faFont textSize11 textClrC flex alignCenter gap10 footerLink">
                                <Arrow />
                                تماس با ما
                            </Link>
                        }
                        <Link to="/rulesAndRegulations" draggable="false" className="faFont textSize11 textClrC flex alignCenter gap10 footerLink">
                            <Arrow />
                            قوانین و مقررات
                        </Link>
                        <Link to="/newsoftheprovinces" draggable="false" className="faFont textSize11 textClrC flex alignCenter gap10 footerLink">
                            <Arrow />
                            اخبار استان ها
                        </Link>
                    </div>
                </div>
                <div className="flex flexCol gap10 row3">
                    <h2 className="faFont textSize14 textClrD justifyText footerTitle rel">
                        تماس با ما
                    </h2>
                    <div className="flex flexCol mar20 gap10">
                        {
                            mainData.phone1 &&
                            <a href={"tel:" + mainData.phone1} className="faFont textSize11 textClrC flex alignCenter gap10 callItem">
                                <Phone />
                                <span>
                                    شماره تماس :
                                </span>
                                <span>
                                {
                                    mainData.phone1
                                }
                                </span>
                            </a>
                        }
                        {
                            mainData.email && <a href={"mailto:" + mainData.email} className="faFont textSize11 textClrC flex alignCenter gap10 callItem">
                                <Email />
                                <span>
                                    ایمیل :
                                </span>
                                <span>
                                    {
                                        mainData.email
                                    }
                                </span>
                            </a>
                        }
                    </div>
                </div>
            </div>
            <button className="jumpToTop faFont textSize11 textClrC flex flexCenter gap5" onClick={()=> scrollToTop() }>
                <ArrowTop />
            </button>
        </section>
        <section className="flex flexCenter copyRightDiv">
            <p className="faFont textSize9 textClrF">تمامی حقوق مادی و معنوی این سایت متعلق به بلادخبر می‌باشد.</p>
        </section>
    </footer>
    </>
}