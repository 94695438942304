import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import AddsList from "./AdsList";
import { Link, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as FaceBookLogo} from "../icon/whiteFacebook.svg";
import {ReactComponent as LinkedinLogo} from "../icon/whiteLinkedin.svg";
import {ReactComponent as TelegramLogo} from "../icon/whiteTelegram.svg";
import {ReactComponent as TwitterLogo} from "../icon/whiteTwitter.svg";
import {ReactComponent as WhatsappLogo} from "../icon/whiteWhatsapp.svg";
import "../style/gallery.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import 'swiper/css';
import {ReactComponent as CloseIcon} from "../icon/close.svg";
import {ReactComponent as ArrowRight } from "../icon/arrowRight.svg";
import {ReactComponent as ArrowLeft } from "../icon/arrowLeft.svg";
import { scrollToTop } from "./Methods";
export default function Gallery(){
    const navigate = useNavigate();
    const params = useParams();
    const {id,cname} = params;
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const [showSlider,setShowSlider] = useState(false);
    const [swiper,setSwiper] = useState(<Swiper/>);

    const [mainData,setMainData] = useState({
        ads: null,
        imgs : null,
        persianName : null,
        title:null,
    });
    useEffect(() => {
        setCity(cname ? cname : "melli");
        fetchData();
    }, []);
    function fetchData(){
        scrollToTop();
        if(!id){
            navigate("/");
            return;
        }
        const cityName = cname ? cname : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("pid",id);
        fetch("https://web.beladkhabar.ir/Enrollment/getAlbum",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(json.msg);
            }
            const tempData = json[0];
            console.log(tempData);
            setMainData({
                ads : tempData.ads,
                imgs : tempData.image,
                persianName : tempData.persianName,
                title:tempData.title,
            });
            document.title = tempData.title;
        })
        .catch((err) => {
        });
    }
    function showSwiper(index){
        swiper.slideTo(index,0);
        setShowSlider(true);
    }
    return <>
        <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>)
                }
                {
                    mainData.persianName && <Link className="faFont textSize9 textClr3 directionLink rel" to={mainData.persianName !== "ملی" ? "/" + cname + "/gallery" : "/gallery"}>
                        آلبوم تصاویر
                    </Link>
                }
                {
                    mainData.title && <p className="faFont textSize9 textClr3">
                        {
                            mainData.title
                        }
                    </p>
                }
            </div>
            <div className="grid newListGrid">
                <div className="flex flexCol">
                    {
                        
                        mainData.imgs ? <div className="flex flexCol imgGridContainer">
                            <div className="grid imgsGrid">
                            {
                                mainData.imgs.map((item,index)=>{
                                    return <div className="flex flexCol imgItemDiv" key={index}>
                                        <img src={item.src} alt={item.title} className="gridImg" onClick={()=>showSwiper(index)} draggable="false"/>
                                    </div>
                                })
                            }
                            </div>
                            <div className="divider mar20"></div>
                            <div className="flex alignCenter justifyBetween shareDiv">
                                <p className="faFont textSize9 textClr3">
                                    اشتراک گذاری مطلب
                                </p>
                                <div className="flex alignCenter gap5">
                                    <a href={"whatsapp://send?text="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <WhatsappLogo />
                                        <span className="hideText">share whatsapp</span>
                                    </a>
                                    <a href={"https://telegram.me/share/url?url="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem ">
                                        <TelegramLogo />
                                        <span className="hideText">share telegram</span>
                                    </a>
                                    <a href={"https://twitter.com/share?url="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <TwitterLogo />
                                        <span className="hideText">share twitter</span>
                                    </a>
                                    <a href={"https://www.linkedin.com/shareArticle?mini=true&url=="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <LinkedinLogo />
                                        <span className="hideText">share linkedin</span>
                                    </a>
                                    <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} className="textSize1 textClr3 flex flexCenter shareSocailItem">
                                        <FaceBookLogo />
                                        <span className="hideText">share facebook</span>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                        : <div className="grid imgsGridLoading">
                            <div className="flex flexCol imgsLoadItem rel"></div>
                            <div className="flex flexCol imgsLoadItem rel"></div>
                            <div className="flex flexCol imgsLoadItem rel"></div>
                            <div className="flex flexCol imgsLoadItem rel"></div>
                            <div className="flex flexCol imgsLoadItem rel"></div>
                            <div className="flex flexCol imgsLoadItem rel"></div>
                        </div>
                    }
                    <a href="https://eitaa.com/beladkhabar" className="flex flexCol mar20" target="_blank" rel="noreferrer" draggable="false">
                        <img src="/image/socialImage.jpg" alt="socialLink" className="middleImage" loading="lazy" draggable="false"/>
                    </a>
                </div>
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
        <div className={showSlider ? "famouseSwiperCloserDiv" : "famouseSwiperCloserDivHide"} onClick={()=>setShowSlider(false)}></div>
        {
            mainData.imgs && mainData.imgs.length > 0 ? <div className={showSlider ? "flex flexCol flexCenter imgSwiperContainer gallerySwiperContainer" : "imgSwiperContainer  hideSlider"}>
                <div className="flex flexCol imgSwiperParentDiv rel" >
                    <button className="flex flexCenter closeSliderBtn" onClick={()=>setShowSlider(false)}>
                        <CloseIcon />
                    </button>
                    <Swiper className="swiper galleryImgSwiper" 
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={1}
                        onSwiper={(swiper) => setSwiper(swiper)}
                        >
                        {
                            mainData.imgs.map((item,index)=>{
                                return <SwiperSlide key={index}>
                                    <img src={item.src} alt={mainData.fullName} className="swiperListImg " draggable="false"/>
                                </SwiperSlide>
                            })
                        }
                    </Swiper>
                    <div className="flex sliderNavDiv galleryNavsDiv">
                        <button className="prevSlide flex flexCenter" onClick={()=>swiper.slidePrev()}>
                            <ArrowRight />
                            <span className="hideText">prev</span>
                        </button>
                        <button className="nextSlide flex flexCenter" onClick={()=>swiper.slideNext()}>
                            <ArrowLeft />
                            <span className="hideText">next</span>
                        </button>
                    </div>
                </div>
            </div>
            : ""
        }
    </>
}