import React, {  useRef } from "react";
import 'swiper/css';
import '../style/userComment.css';
import {ReactComponent as QuotLogo} from "../icon/quot.svg";
import { Link } from "react-router-dom";
export default function UserComments(props){
    const data = props.data;
    const winX = useRef(0);
    const time = useRef(850);
    if(data.comments){
        if(data.comments.length > 0){
            let win = window,
            doc = document,
            docElem = doc.documentElement,
            body = doc.getElementsByTagName('body')[0],
            x = win.innerWidth || docElem.clientWidth || body.clientWidth;
            let mul = 15;
            if(x > 1350){
                x = 1350;
            }
            if(x>768){
                mul = 100;
            }
            const commentTest = document.querySelector(".commentTest");
            if(commentTest){
                data && data.comments && data.comments.map((item)=>{
                    const span = document.createElement("span");
                    span.innerText = item.text;
                    commentTest.append(span)
                })
                winX.current = Math.floor(x);
                time.current = Math.floor(commentTest.clientWidth/x * mul);

            }
        }
    }
    return <>
    {
        !data.comments ? <div className="commentLoadingDiv rel">
            <p className="commentTest faFont textSize9"></p>
        </div> : 
        data && data.comments && data.comments.length > 0 ? <section className="flex flexCol userCommentsSection">
            <div className="latestTitleContainer ">
                <div className="flex alignCenter justifyBetween container latestTitleDiv rel">
                    <p className="faFont textSize12 textClrF mar5">
                        پیام‌های کاربران
                    </p>
                    <Link to="contactus" className="faFont textSize9 textClrF seeAllGallery flex alignCenter gap5">
                        ارسال پیام
                    </Link>
                </div>
            </div>
            <div className="flex commentList alignCenter hideScrollBar container">
                <p className="faFont textSize9 textClrF commentText flex alignCenter" style={{animation:"movingList "+time.current+"s linear infinite" ,paddingRight:winX.current}} >
                    {
                        data.comments.map((item,index)=>{
                            return <span className="commentSpan flex alignCenter gap10" key={index}><QuotLogo/> {item.text}</span>
                        })
                    }
                </p>
            </div>
        </section> : ""
    }
    </>
}