import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { Link, useLocation, useParams } from "react-router-dom";
import "../style/gridPage.css";
import AddsList from "./AdsList";
import {ReactComponent as VisitLogo} from "../icon/Visit.svg";
import PagePagintaion from "./PagePagination";
import { scrollToTop } from "./Methods";
export default function GridPage(props){
    const {pageName , pageTitle} = props.data;
    const params = useParams();
    const {currentCity} = useContext(AppContext);
    const [city, setCity] = currentCity;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentPage = useRef(1);
    const [mainData,setMainData] = useState({
        ads: null,
        content : null,
        persianName : null,
        totalPage : 0,
    });
    useEffect(() => {
        document.title = pageTitle;
        setCity(params.name ? params.name : "melli");
        fetchData();
    }, []);
    function fetchData(){
        scrollToTop();
        currentPage.current = queryParams.get("p") ? queryParams.get("p") : 1;
        const cityName = params.name ? params.name : "melli";
        const data = new FormData();
        data.append("name", cityName);
        data.append("stateName",pageName)
        data.append("pageIndex",currentPage.current);
        data.append("pageCount",12);
        fetch("https://web.beladkhabar.ir/Enrollment/GetGVP",{
            method:"POST",
            body:data,
        })
        .then((res) => {
            if (!res.ok) {
            throw new Error("102");
            }
            return res.json();
        })
        .then((json) => {
            if (json.error) {
                throw new Error(json.msg);
            }
            const tempData = json[0];
            setMainData({
                ads:tempData.ads,
                content : tempData.contents[0].dt[0].gallery,
                totalPage : Math.ceil(tempData.contents[0].dt[0].count / 12),
                persianName : tempData.persianName
            })
        })
        .catch((err) => {
        });
    }
    function changePage(newPage){
        queryParams.set("p",newPage);
        window.history.pushState({},"",`${window.location.pathname}?${queryParams}`);
        fetchData();
    }
    return <>
        <section className="flex flexCol container">
            <div className="flex alignCenter directionDiv mar20">
                <Link className="faFont textSize9 textClr3 directionLink rel" to="/">صفحه اصلی</Link>
                {
                    mainData.persianName && (mainData.persianName !== "ملی" && <Link className="faFont textSize9 textClr3 directionLink rel" to={"/" + city}>{mainData.persianName}</Link>)
                }
                <p className="faFont textSize9 textClr3">
                {
                    pageTitle
                }
                </p>
            </div>
            <div className="grid newListGrid">
                <div className="flex flexCol">
                    {
                        !mainData.content ? <div className="grid gridPageLoading">
                            <div className="flex flexCol rel gridPageLoadingItem"></div>
                            <div className="flex flexCol rel gridPageLoadingItem"></div>
                            <div className="flex flexCol rel gridPageLoadingItem"></div>
                            <div className="flex flexCol rel gridPageLoadingItem"></div>
                            <div className="flex flexCol rel gridPageLoadingItem"></div>
                            <div className="flex flexCol rel gridPageLoadingItem"></div>
                        </div>: 
                        mainData.content.length > 0 ? <div className="grid gridPageMainGrid">
                            {
                                mainData.content.map((item,index)=>{
                                    return <div className="flex flexCol gridPageItem rel" key={index}>
                                        <Link to={item.link} className="flex flexCol gridPageLinkDiv" draggable="false">
                                            <img src={item.src} alt={item.title} draggable={false} className="gridPageImg" />
                                        </Link>
                                        <Link to={item.link} className="faFont textSize1 textClr3 gridPageTextLink" draggable="false">
                                            {
                                                item.title
                                            }
                                        </Link>
                                        {
                                            pageName === "Videos" && <p className="faFont textSize9 textClrF visitText flex alignCenter gap5">
                                                <VisitLogo />
                                                <span>
                                                {
                                                    item.visiteCount
                                                }
                                                </span>
                                            </p>
                                        }
                                    </div>
                                })
                            }
                        </div> : 
                        "" 
                    }

                    <PagePagintaion data={{totalNumber:mainData.totalPage,
                        changePage : changePage,
                        currentPage:currentPage}} />
                </div>
                <div className="flex flexCol row1LeftDiv mar20">
                    <AddsList data={{"ads":mainData.ads}}/>
                </div>
            </div>
        </section>
    </>
}